import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export type PasswordTextFieldProps = TextFieldProps;

const useStyles = makeStyles((theme) => ({
  togglePassword: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

// Material-UI text field enhanced for password input.
// Adds an icon button to toggle password visibility.
export const PasswordTextField = React.forwardRef<
  typeof TextField,
  PasswordTextFieldProps
>(({ children, ...props }, ref) => {
  const disabled = props.disabled;
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        type: showPassword ? 'text' : 'password',
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              title={showPassword ? 'Hide password' : 'Show password'}
              enterDelay={500}
              leaveDelay={200}
            >
              <IconButton
                disabled={disabled}
                classes={{
                  root: classes.togglePassword,
                }}
                aria-label="Toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(event) => event.preventDefault()}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      {...props}
    >
      {children}
    </TextField>
  );
});
