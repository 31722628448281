import { createAction } from '@reduxjs/toolkit';

// Unauthorized
export const unauthorizedError = createAction('error/unauthorized');

// Forbidden: Account suspended
export const accountSuspended = createAction('error/forbidden/suspended');

// Forbidden: Password change required
export const passwordChangeRequired = createAction(
  'error/forbidden/passwordChangeRequired'
);

// Forbidden: User disabled
export const userDisabled = createAction('error/forbidden/userDisabled');

// Forbidden: Other
export const forbiddenError = createAction('error/forbidden/other');
