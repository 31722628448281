import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { unwrapResult } from '@reduxjs/toolkit';

import { AppDispatch } from 'App/store';
import { LoadingButton } from 'Components/LoadingButton';
import { PasswordTextField } from 'Components/PasswordTextField';
import { login } from 'Features/Auth/authSlice';
import { useIsMounted } from 'Hooks/useIsMounted';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
  },
  forgotButton: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  errorIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(1),
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  togglePassword: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Inputs {
  username: string;
  password: string;
}

export function LoginForm() {
  const dispatch: AppDispatch = useDispatch();
  const { control, errors, handleSubmit } = useForm<Inputs>();
  const isMounted = useIsMounted();
  const [stayLoggedIn, setstayLoggedIn] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const classes = useStyles();

  async function onSubmit({ username, password }: Inputs) {
    setErrorMessage(null);
    setIsSubmitting(true);

    let errorMessage = null;

    const result = await dispatch(login({ username, password, stayLoggedIn }));

    try {
      unwrapResult(result);
    } catch (error) {
      errorMessage = error.message ?? 'Unknown error';
    }

    if (isMounted.current) {
      setErrorMessage(errorMessage);
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Helmet title="Login" />
      <Box margin={4}>
        <Typography variant="h3" gutterBottom>
          Log In
        </Typography>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
        >
          <Box>
            <Controller
              as={TextField}
              name="username"
              control={control}
              defaultValue=""
              rules={{ required: 'Required' }}
              error={Boolean(errors.username)}
              helperText={errors.username?.message}
              disabled={isSubmitting}
              fullWidth
              autoFocus
              required
              variant="outlined"
              margin="normal"
              id="username"
              label="Username or email"
            />
          </Box>
          <Box>
            <Controller
              as={PasswordTextField}
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: 'Required' }}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              disabled={isSubmitting}
              fullWidth
              required
              variant="outlined"
              margin="normal"
              id="password"
              label="Password"
              autoComplete="current-password"
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={stayLoggedIn}
                onChange={(event) => {
                  setstayLoggedIn(event.target.checked);
                }}
                color="primary"
                name="stayLoggedIn"
              />
            }
            label="Stay logged in"
          />
          <Box margin="normal">
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              disabled={isSubmitting}
              loading={isSubmitting}
              className={classes.submit}
            >
              Log In
            </LoadingButton>
          </Box>
          <Button
            component={Link}
            to="/login/forgot"
            color="primary"
            size="small"
            className={classes.forgotButton}
          >
            Forgot password?
          </Button>
          {errorMessage && (
            <Typography
              variant="body2"
              color="error"
              className={classes.errorMessage}
            >
              <ErrorIcon className={classes.errorIcon} />
              Error: {errorMessage}
            </Typography>
          )}
        </form>
      </Box>
    </>
  );
}
