import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

// Get a user-friendly display of a date of last activity
export const formatLastActivity = (lastActivity: string | null) => {
  // Check if token has never been used
  if (lastActivity === null) {
    return 'Never';
  }

  // Calculate the number of days since the token was last used
  const today = dayjs.utc().startOf('day');
  const lastActivityDay = dayjs.utc(lastActivity);
  const diffDays = lastActivityDay.diff(today, 'day');

  // Check if token was used within the last day
  if (diffDays >= 0) {
    return 'Today';
  }

  // Get user-friendly representation of relative time
  return dayjs.duration(diffDays, 'day').humanize(true);
};
