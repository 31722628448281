import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as api from 'Api';
import { accountSuspended } from 'App/errorActions';
import { RootState } from 'App/rootReducer';
import { loggedOut } from 'Features/Auth/logout';

export interface GetAccountInfoResult {
  subscriptionState: api.SubscriptionState;
  name: string;
  ownerEmail: string | null;
  expirationDate: string | null;
  products: api.Product[];
  tonePrivacyId: number | null;
}

/**
 * Get account information.
 */
export const getAccountInfo = createAsyncThunk<
  GetAccountInfoResult,
  void,
  {
    rejectValue: api.ErrorResponse;
  }
>('user/getAccountInfo', async (request, { rejectWithValue }) => {
  try {
    const { data } = await api.getAccount();

    return {
      subscriptionState: data.subscription_state,
      name: data.name,
      ownerEmail: data.owner_email,
      expirationDate: data.expiration_date,
      products: data.products,
      tonePrivacyId: data.tone_privacy_id,
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data as api.ErrorResponse);
  }
});

export interface AccountState {
  subscriptionState: api.SubscriptionState | null;
  name: string | null;
  ownerEmail: string | null;
  expirationDate: string | null;
  products: api.Product[];
  tonePrivacyId: number | null;
}

const initialState: AccountState = {
  subscriptionState: null,
  name: null,
  ownerEmail: null,
  expirationDate: null,
  products: [],
  tonePrivacyId: null,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get account info
    builder.addCase(getAccountInfo.fulfilled, (state, { payload }) => {
      state.subscriptionState = payload.subscriptionState;
      state.name = payload.name;
      state.ownerEmail = payload.ownerEmail;
      state.expirationDate = payload.expirationDate;
      state.products = payload.products;
      state.tonePrivacyId = payload.tonePrivacyId;
    });

    // Account suspended
    builder.addCase(accountSuspended, (state) => {
      state.subscriptionState = 'suspended';
    });

    // Logged out
    builder.addCase(loggedOut, () => {
      return initialState;
    });
  },
});

// Selectors
export const selectAccount = (state: RootState) => state.account;

export default accountSlice.reducer;
