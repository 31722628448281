import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { selectAccount } from 'Features/Account/accountSlice';
import { calculateTone } from 'Utils/calculateTone';

type Profile = 'compression' | 'pkab' | 'pkab2' | 'standard' | 'standard2';

interface ToneDurationInfo {
  numBytes: number | null;
  overheadSeconds: number | null;
  payloadSeconds: number | null;
  totalSeconds: number | null;
}

const useStyles = makeStyles((theme) => ({
  totalRow: {
    backgroundColor: theme.palette.darkBlue.main,
    '& td': {
      color: theme.palette.common.white,
      fontWeight: 'bold',
      border: 0,
      '&:first-child': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
      },
      '&:last-child': {
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px',
      },
    },
  },
  toneCalculatorForm: {
    background: theme.palette.darkBlue.main,
    flex: 1,
  },
  noBottomBorderTableRow: {
    '& td': {
      border: 0,
    },
  },
  toneProfileSelect: {
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
      borderRadius: '4px',
    },
    '& .MuiSelect-root': {
      padding: '18px 14px',
      paddingRight: '22px',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  inputLabel: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  payloadInput: {
    width: '100%',
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.common.white,
    },
  },
  paper: {
    overflow: 'hidden',
  },
}));

interface ProfileSelectProps {
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  value: Profile;
  name: string;
}

function ProfileSelect({ onChange, value, name }: ProfileSelectProps) {
  const classes = useStyles();

  const profiles = [
    {
      id: 'standard',
      name: 'Standard',
      bitsPerSecond: '33.3',
    },
    {
      id: 'standard2',
      name: 'Standard 2.0',
      bitsPerSecond: '33.3',
    },
    {
      id: 'compression',
      name: 'Compression',
      bitsPerSecond: '33.3',
    },
    {
      id: 'pkab',
      name: 'PKAB',
      bitsPerSecond: '1000',
    },
    {
      id: 'pkab2',
      name: 'PKAB 2.0',
      bitsPerSecond: '1000',
    },
  ];

  return (
    <Box className={classes.toneProfileSelect}>
      <FormControl>
        <Select
          labelId="profile-label"
          id="profile-select"
          name={name}
          value={value}
          onChange={onChange}
          disableUnderline
        >
          {profiles.map((profile) => (
            <MenuItem
              key={profile.id}
              value={profile.id}
            >{`${profile.name} (${profile.bitsPerSecond} bits/s)`}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

function formatDuration(duration: number | null) {
  if (duration === null) {
    return null;
  }
  return `${duration.toFixed(3)}`;
}

type ToneDurationTableProps = ToneDurationInfo;

function ToneDurationTable({
  numBytes,
  overheadSeconds,
  payloadSeconds,
  totalSeconds,
}: ToneDurationTableProps) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">Bytes</TableCell>
            <TableCell align="right">Duration (seconds)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              Overhead<sup>*</sup>
            </TableCell>
            <TableCell align="right">–</TableCell>
            <TableCell align="right">
              {formatDuration(overheadSeconds)}
            </TableCell>
          </TableRow>
          <TableRow className={classes.noBottomBorderTableRow}>
            <TableCell>Payload</TableCell>
            <TableCell align="right">{numBytes}</TableCell>
            <TableCell align="right">
              {formatDuration(payloadSeconds)}
            </TableCell>
          </TableRow>
          <TableRow className={classes.totalRow}>
            <TableCell>Total</TableCell>
            <TableCell align="right" colSpan={2}>
              {formatDuration(totalSeconds)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface Inputs {
  profile: Profile;
  numBytes: number;
}

// Initial input values
const defaultValues: Inputs = {
  profile: 'standard',
  numBytes: 6,
};

export function ToneCalculator() {
  const { control, errors, getValues, handleSubmit } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues,
  });
  const { tonePrivacyId } = useSelector(selectAccount);
  const [toneDurationInfo, setToneDurationInfo] = useState<ToneDurationInfo>({
    numBytes: null,
    overheadSeconds: null,
    payloadSeconds: null,
    totalSeconds: null,
  });
  const classes = useStyles();

  // Form submission callback when inputs are valid
  function onValid({ profile, numBytes }: Inputs) {
    const includeTonePrivacyId = tonePrivacyId !== null;
    const result = calculateTone(profile, numBytes, includeTonePrivacyId);
    setToneDurationInfo({ numBytes, ...result });
  }

  // Form submission callback when inputs are invalid
  function onInvalid(errors: any) {
    setToneDurationInfo({
      numBytes: null,
      overheadSeconds: null,
      payloadSeconds: null,
      totalSeconds: null,
    });
  }

  // Profile change event handler
  function handleProfileChange(
    event: React.ChangeEvent<{ value: unknown }>,
    onChange: (...event: any[]) => void
  ) {
    onChange(event);
    handleSubmit(onValid, onInvalid)();
  }

  // Number of bytes change event handler
  function handleNumBytesChange(
    event: React.ChangeEvent<{ value: unknown }>,
    onChange: (...event: any[]) => void
  ) {
    onChange(event);
    handleSubmit(onValid, onInvalid)();
  }

  // Calculate tone for default input values after initial render
  useEffect(() => {
    const { profile, numBytes } = defaultValues;
    const includeTonePrivacyId = tonePrivacyId !== null;
    const result = calculateTone(profile, numBytes, includeTonePrivacyId);
    setToneDurationInfo({ numBytes, ...result });
  }, [tonePrivacyId]);

  return (
    <>
      <Container maxWidth="lg">
        <Box display="flex" flexDirection={['column', 'column', 'row']}>
          <Box pr={[0, 0, 1]} mb={[3, 3, 0]} flexBasis="33.3%">
            <Typography variant="h3" gutterBottom>
              Tone Calculator
            </Typography>
            <Typography>
              Input your tone's properties to calculate its duration.
            </Typography>
          </Box>
          <Box pl={[0, 0, 1]} flexBasis="66.6%">
            <Paper className={classes.paper}>
              <form data-testid="form">
                <Box display="flex" flexDirection={['column', 'column', 'row']}>
                  <Box
                    display="flex"
                    flexDirection={['column', 'row', 'column']}
                    p={3}
                    className={classes.toneCalculatorForm}
                    minWidth={['0', '0', '280px']}
                  >
                    <Box mr={[0, 2, 0]} width={['100%', '50%', '100%']}>
                      <InputLabel className={classes.inputLabel}>
                        Tone Profile
                      </InputLabel>
                      <Controller
                        control={control}
                        name="profile"
                        render={({ onChange, value, name }) => (
                          <ProfileSelect
                            value={value}
                            onChange={(event) =>
                              handleProfileChange(event, onChange)
                            }
                            name={name}
                          />
                        )}
                      />
                    </Box>

                    <Box width={['100%', '50%', '100%']}>
                      <InputLabel className={classes.inputLabel}>
                        Payload Size (bytes)
                      </InputLabel>
                      <Controller
                        control={control}
                        name="numBytes"
                        render={({ onChange, value, name, ref }) => (
                          <>
                            <NumberFormat
                              customInput={TextField}
                              decimalScale={0}
                              allowNegative={false}
                              name={name}
                              value={value}
                              onChange={(event) =>
                                handleNumBytesChange(event, onChange)
                              }
                              inputRef={ref}
                              inputProps={{
                                maxLength: 4,
                              }}
                              error={Boolean(errors.numBytes)}
                              helperText={errors.numBytes?.message}
                              variant="outlined"
                              className={classes.payloadInput}
                            />
                          </>
                        )}
                        rules={{
                          required: 'Required',
                          validate: {
                            length: (value) => {
                              let maxBytes = 0;

                              const profile = getValues('profile');
                              switch (profile) {
                                case 'compression':
                                case 'standard':
                                case 'standard2':
                                  maxBytes = 255;
                                  break;
                                case 'pkab':
                                case 'pkab2':
                                  maxBytes = 3000;
                                  break;
                                default:
                                  break;
                              }

                              if (value > maxBytes) {
                                return `Maximum payload for this profile is ${maxBytes} bytes`;
                              }

                              return true;
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box pb={3} px={3}>
                    <ToneDurationTable {...toneDurationInfo} />
                    <Box mt={2}>
                      <Typography variant="body2" component="p">
                        <sup>*</sup>Overhead includes the preamble, header,
                        integrity check information (CRC), and guard interval
                        for the tone.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Paper>
          </Box>
        </Box>
      </Container>
    </>
  );
}
