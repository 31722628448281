// Calculate the duration of a tone.
export const calculateTone = (
  profile: 'compression' | 'pkab' | 'pkab2' | 'standard' | 'standard2',
  numBytes: number,
  includeTonePrivacyId: boolean,
  format: 'wav' | 'mp3' = 'wav',
  sampleRate: 44100 | 48000 = 44100
) => {
  let bitsPerSecond: number;
  let overheadSeconds: number;

  switch (profile) {
    case 'standard':
    case 'compression':
      bitsPerSecond = 33.333333;
      overheadSeconds = 1.28;
      break;
    case 'standard2':
      bitsPerSecond = 33.333333;
      overheadSeconds = 1.695;
      break;
    case 'pkab':
    case 'pkab2':
      bitsPerSecond = 1000;
      overheadSeconds = 0.377;
      break;
    default:
      throw new Error('Invalid profile');
  }

  // Add one byte to overhead for tone privacy ID
  if (includeTonePrivacyId) {
    overheadSeconds += 8 / bitsPerSecond;
  }

  const payloadSeconds = numBytes * ((1 / bitsPerSecond) * 8);
  let totalSeconds = overheadSeconds + payloadSeconds;

  if (format === 'mp3') {
    // LAME adds 576 samples of padding to the beginning and 288 samples of
    // padding to the end of all files. Decoder delay adds 529 samples.
    let totalSamples = Math.round(totalSeconds * sampleRate) + 1393;
    // MP3 frames contain 1152 samples. When decoding, the length of a file
    // will be calculated based on complete frames.
    totalSamples = Math.ceil(totalSamples / 1152) * 1152;
    totalSeconds = totalSamples / sampleRate;
  }

  return {
    overheadSeconds: overheadSeconds,
    payloadSeconds: payloadSeconds,
    totalSeconds: totalSeconds,
  };
};
