import { AxiosResponse } from 'axios';

export interface PaginationParams {
  limit?: number;
  offset?: number;
}

/**
 * Utility function to fetch all available pages for a paginated API call.
 */
export async function getAllPages<R extends PaginationParams, T>(
  apiCall: (request: R) => Promise<AxiosResponse<Array<T>>>,
  request: R
) {
  const data = new Array<T>();
  let limit = 100;
  let offset = 0;

  do {
    request.limit = limit;
    request.offset = offset;

    const resp = await apiCall(request);
    data.push(...resp.data);

    limit = resp.headers['pagination-limit'];
    offset = resp.headers['pagination-offset'];
  } while (limit && offset);

  return data;
}
