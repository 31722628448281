import axios, { AxiosResponse } from 'axios';

// Axios instance to call Hydra API.
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  transformResponse: (data) => {
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (error) {}
    }

    return data.result ?? data;
  },
});

// Response interceptor to simplify pagination handling.
//
// For paginated results Hydra returns a 'results-next' header containing the
// full URL of the next page. This function parses and transforms that header
// into two new headers: 'pagination-limit' and 'pagination-offset'.
const parsePagination = (response: AxiosResponse) => {
  const pagination = response.headers['results-next'];
  delete response.headers['results-next'];

  if (pagination) {
    const searchParams = new URL(pagination).searchParams;
    const limit = searchParams.get('limit');
    const offset = searchParams.get('offset');

    if (limit !== null && offset !== null) {
      response.headers['pagination-limit'] = limit;
      response.headers['pagination-offset'] = offset;
    }
  }

  return response;
};

axiosInstance.interceptors.response.use(parsePagination);

export { axiosInstance };
