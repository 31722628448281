import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  grid: {
    minHeight: '100vh',
  },
}));

// Page displayed when user navigates to an invalid route.
export function NotFound() {
  const history = useHistory();
  const classes = useStyles();
  const title = 'Page Not Found';

  return (
    <>
      <Helmet title={title} />
      <Container maxWidth="xs" component="main">
        <Grid container alignItems="center" className={classes.grid}>
          <Box width="100%">
            <Typography
              component="h2"
              variant="h4"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              The page you are looking for doesn't exist or has been moved.
            </Typography>
            <Box mt={4} textAlign="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => history.push('/')}
              >
                Go to the homepage
              </Button>
            </Box>
          </Box>
        </Grid>
      </Container>
    </>
  );
}
