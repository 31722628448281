import { createAction } from '@reduxjs/toolkit';

import * as api from 'Api';
import { AppThunk } from 'App/store';

/**
 * Action dispatched when logged out.
 */
export const loggedOut = createAction('auth/loggedOut');

/**
 * Log out of the Portal.
 */
export const logout = (): AppThunk => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const isAuthenticated = auth.token !== null;

    // Dispatch logged out action optimistically
    dispatch(loggedOut());

    if (isAuthenticated) {
      try {
        await api.logout();
      } catch {
        // ignore errors
      }
    }
  };
};
