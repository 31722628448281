import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { unwrapResult } from '@reduxjs/toolkit';

import { AppDispatch } from 'App/store';
import { getAccountInfo, selectAccount } from 'Features/Account/accountSlice';
import { selectIsAccountAdmin } from 'Features/User/userSlice';
import { useIsMounted } from 'Hooks/useIsMounted';
import { productDisplayName } from 'Utils/productDisplayName';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(4),
  },
  table: {
    '& th': {
      width: '180px',
    },
  },
  tableHeader: {
    verticalAlign: 'top',
  },
}));

export function AccountInfo() {
  const dispatch: AppDispatch = useDispatch();
  const isMounted = useIsMounted();
  const {
    subscriptionState,
    name,
    ownerEmail,
    expirationDate,
    products,
    tonePrivacyId,
  } = useSelector(selectAccount);
  const isAccountAdmin = useSelector(selectIsAccountAdmin);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const classes = useStyles();

  useEffect(() => {
    async function fetchAccountInfo() {
      let errorMessage = null;
      const result = await dispatch(getAccountInfo());

      try {
        unwrapResult(result);
      } catch (error) {
        errorMessage = error.message ?? 'Unknown error';
      }

      if (isMounted.current) {
        setErrorMessage(errorMessage);
        setLoading(false);
      }
    }

    fetchAccountInfo();
  }, [dispatch, isMounted]);

  const rows = [
    { name: 'Name', value: () => name },
    { name: 'Owner', value: () => ownerEmail ?? 'None' },
    {
      name: 'Status',
      value: () => {
        return subscriptionState !== null
          ? subscriptionState[0].toUpperCase() + subscriptionState.substring(1)
          : null;
      },
    },
    { name: 'Expiration Date', value: () => expirationDate },
    {
      name: 'Enabled Products',
      value: () => (
        <>
          {products.indexOf('radius') !== -1 && (
            <div>{productDisplayName('radius')}</div>
          )}
          {products.indexOf('point') !== -1 && (
            <div>{productDisplayName('point')}</div>
          )}
          {products.indexOf('legacy') !== -1 && (
            <div>{productDisplayName('legacy')}</div>
          )}
        </>
      ),
    },
    {
      name: 'Tone Privacy',
      value: () => (tonePrivacyId !== null ? 'Enabled' : 'Disabled'),
    },
  ];

  let content = null;

  if (loading) {
    content = (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else if (errorMessage) {
    content = <Typography color="error">Error: {errorMessage}</Typography>;
  } else {
    content = (
      <TableContainer>
        <Table className={classes.table}>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableHeader}
                >
                  <Typography variant="button" color="textSecondary" noWrap>
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell>{row.value()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Container maxWidth="md">
      <Card className={classes.card}>
        <CardContent>{content}</CardContent>
        {isAccountAdmin && (
          <CardActions>
            <Button
              component={Link}
              to="/account/users"
              color="primary"
              size="small"
            >
              Manage Users
            </Button>
          </CardActions>
        )}
      </Card>
    </Container>
  );
}
