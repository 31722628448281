import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const LoadingIndicator = <CircularProgress color="inherit" size={20} />;

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  loadingIndicator?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    position: 'absolute',
    visibility: 'visible',
    display: 'flex',
  },
  label: {},
  labelLoading: {
    visibility: 'hidden',
  },
}));

// Button that supports displaying an inline loading indicator.
// TODO: use Material-UI LoadingButton when released:
// https://next.material-ui.com/components/buttons/#loading-buttons
export const LoadingButton = React.forwardRef<
  HTMLButtonElement,
  LoadingButtonProps
>((props: LoadingButtonProps, ref) => {
  const {
    children,
    classes,
    className,
    disabled = false,
    loading = false,
    loadingIndicator = LoadingIndicator,
    ...other
  } = props;

  const styles = useStyles();

  return (
    <Button
      className={className}
      disabled={disabled || loading}
      ref={ref}
      classes={{
        label: clsx({
          [styles.labelLoading]: loading,
        }),
      }}
      {...other}
    >
      {loading && (
        <div className={styles.loadingIndicator}>{loadingIndicator}</div>
      )}
      {children}
    </Button>
  );
});
