import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    darkBlue: Palette['primary'];
    lightGreen: Palette['primary'];
    lightBlue: Palette['primary'];
  }
  interface PaletteOptions {
    darkBlue: PaletteOptions['primary'];
    lightGreen: PaletteOptions['primary'];
    lightBlue: PaletteOptions['primary'];
  }
}

const primary = '#6073ef';
const secondary = '#5d48aa';
const lightBlue = '#a5d4ff';
const darkBlue = '#3B465F';
const lightGreen = '#5bbabc';

export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      darkBlue: {
        main: darkBlue,
      },
      lightGreen: {
        main: lightGreen,
      },
      lightBlue: {
        main: lightBlue,
      },
    },
    typography: {
      h1: {
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontSize: '3.75rem',
      },
      h2: {
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontSize: '2.75rem',
      },
      h3: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '2rem',
      },
      h4: {
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontSize: '1.75rem',
      },
      h5: {
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontSize: '1.5rem',
      },
      h6: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '1.25rem',
      },
      subtitle1: {
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontSize: '1rem',
      },
      subtitle2: {
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontSize: '.875rem',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          a: {
            color: primary,
            textDecoration: 'none',
          },
        },
      },
    },
  })
);
