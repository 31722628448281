import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import * as api from 'Api';
import { RouteNotFound } from 'App/RouteNotFound';
import { Layout } from 'Features/Main/Layout';
import { useFetchProject } from 'Hooks/useFetchProject';

interface RouteParams {
  projectId: string;
}

export interface ProjectLayoutProps {
  getContent: (
    project: api.Project
  ) => {
    documentTitle?: string;
    title?: string;
    subtitle?: string;
    drawerContent?: React.ReactNode;
    content?: React.ReactNode;
  };
}

export const ProjectLayout = ({ getContent }: ProjectLayoutProps) => {
  const { projectId } = useParams<RouteParams>();
  const { loading, errorMessage, project } = useFetchProject(projectId);

  if (errorMessage) {
    return <Typography color="error">Error: {errorMessage}</Typography>;
  } else if (!loading && !project) {
    return <RouteNotFound />;
  } else if (!project) {
    return <Layout loading={loading} />;
  }

  const { documentTitle, title, subtitle, drawerContent, content } = getContent(
    project
  );

  return (
    <Layout
      loading={loading}
      documentTitle={documentTitle}
      title={title}
      subtitle={subtitle}
      drawerContent={drawerContent}
      content={content}
    />
  );
};

// TODO: ProjectLayout test
