import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorIcon from '@material-ui/icons/Error';
import { unwrapResult } from '@reduxjs/toolkit';

import * as api from 'Api';
import { AppDispatch } from 'App/store';
import { LoadingButton } from 'Components/LoadingButton';
import { PasswordTextField } from 'Components/PasswordTextField';
import { getUserInfo } from 'Features/User/userSlice';
import { useIsMounted } from 'Hooks/useIsMounted';
import { validatePassword } from 'Utils/validatePassword';

import { logout } from './logout';

const useStyles = makeStyles((theme) => ({
  grid: {
    minHeight: '100vh',
  },
  form: {
    marginTop: theme.spacing(1),
  },
  goBackButton: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  errorIcon: {
    marginRight: theme.spacing(1),
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  togglePassword: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Inputs {
  currentPassword: string;
  newPassword: string;
}

export function ChangePassword() {
  const dispatch: AppDispatch = useDispatch();
  const { control, errors, handleSubmit, setError } = useForm<Inputs>();
  const isMounted = useIsMounted();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isAdvancing, setIsAdvancing] = useState(false);
  const classes = useStyles();

  async function onSubmit({ currentPassword, newPassword }: Inputs) {
    setErrorMessage(null);
    setIsSubmitting(true);

    try {
      const { data } = await api.changePassword({
        current_password: currentPassword,
        new_password: newPassword,
      });
      setSuccessMessage(data.message);
      setDialogOpen(true);
    } catch (error) {
      let errorDetail = null;

      if (error.response) {
        // Parse the errored field from message to indicate visually which
        // field has an error. Show the server-side error message only for the
        // new password field. Show a generic error if the specific field
        // isn't provided.
        const { code, message } = error.response.data;
        if (code === 'invalid') {
          // The error message may have form: "<field_name>: <message>".
          // 'current_password' errors if the password is incorrect.
          // 'new_password' errors if it fails server-side validation.
          const [fieldName, fieldError] = message
            .split(':')
            .map((str: string) => str.trim());
          if (fieldName === 'current_password') {
            setError('currentPassword', { message: '' });
            errorDetail = 'Current password is incorrect.';
          } else if (fieldName === 'new_password') {
            setError('newPassword', { message: '' });
            errorDetail = fieldError;
          }
        }
      } else {
        errorDetail = error.message ?? 'Unknown error.';
      }

      if (!isMounted.current) {
        return;
      }

      // Construct error message with error details.
      let errorMessage = 'Unable to change your password.';
      if (errorDetail) {
        if (!errorDetail.endsWith('.')) {
          errorDetail += '.';
        }
        errorMessage += ' ' + errorDetail;
      }

      setErrorMessage(errorMessage);
    }

    setIsSubmitting(false);
  }

  async function handleClose() {
    setIsAdvancing(true);

    // Update user information
    const result = await dispatch(getUserInfo());
    let error = false;
    try {
      unwrapResult(result);
    } catch {
      error = true;
      setIsAdvancing(false);
    }

    if (!error && isMounted.current) {
      setDialogOpen(false);
    }
  }

  const title = 'Change Password';

  return (
    <>
      <Helmet title={title} />
      <Container maxWidth="xs" component="main">
        <Grid container alignItems="center" className={classes.grid}>
          <Box>
            <Typography
              component="h2"
              variant="h5"
              color="textPrimary"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              For security purposes, please create a new password. Your password
              must have between 6 and 25 characters, be alphanumeric, and
              include at least one special character (!@#$%^&*_) and one number.
              No other special characters are allowed.
            </Typography>
            <form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              className={classes.form}
            >
              <Box>
                <Controller
                  as={PasswordTextField}
                  name="currentPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Required',
                  }}
                  error={Boolean(errors.currentPassword)}
                  helperText={errors.currentPassword?.message}
                  disabled={isSubmitting}
                  fullWidth
                  required
                  variant="outlined"
                  margin="normal"
                  id="currentPassword"
                  label="Current password"
                  autoComplete="current-password"
                />
              </Box>
              <Box>
                <Controller
                  as={PasswordTextField}
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Required',
                    validate: (password) => {
                      let message;
                      try {
                        validatePassword(password);
                      } catch (error) {
                        message = error.message;
                      }
                      return message ?? true;
                    },
                  }}
                  error={Boolean(errors.newPassword)}
                  helperText={errors.newPassword?.message}
                  disabled={isSubmitting}
                  fullWidth
                  required
                  variant="outlined"
                  margin="normal"
                  id="newPassword"
                  label="New password"
                  autoComplete="new-password"
                />
              </Box>
              <Box margin="normal">
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  disableElevation
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  className={classes.submit}
                >
                  Submit
                </LoadingButton>
              </Box>
              <Button
                component={Link}
                to="/login"
                color="primary"
                size="small"
                className={classes.goBackButton}
                onClick={async () => {
                  await dispatch(logout());
                }}
                startIcon={<ArrowBackIcon />}
              >
                Go back to Log In
              </Button>
              {errorMessage && (
                <Typography color="error" className={classes.errorMessage}>
                  <ErrorIcon className={classes.errorIcon} />
                  Error: {errorMessage}
                </Typography>
              )}
            </form>
          </Box>
        </Grid>
      </Container>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {successMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            autoFocus
            color="primary"
            onClick={handleClose}
            disabled={isAdvancing}
            loading={isAdvancing}
          >
            Continue
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
