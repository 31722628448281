import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { forbiddenError, unauthorizedError } from 'App/errorActions';
import { RootState } from 'App/rootReducer';
import { login } from 'Features/Auth/authSlice';
import { loggedOut } from 'Features/Auth/logout';

export interface AppState {
  // Whether app is initialized
  isInitialized: boolean;
  // Whether an unhandled access error occurred; the user should be notified and
  // logged out
  accessError: boolean;
}

const initialState: AppState = {
  isInitialized: false,
  accessError: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // Set whether app is initialized
    setIsInitialized: (state) => {
      state.isInitialized = true;
    },
  },
  extraReducers: (builder) => {
    // Login, logged out
    builder.addMatcher(
      isAnyOf(login.fulfilled, login.rejected, loggedOut),
      (state) => {
        state.accessError = false;
      }
    );

    // Unauthorized error, forbidden error
    builder.addMatcher(isAnyOf(unauthorizedError, forbiddenError), (state) => {
      state.accessError = true;
    });
  },
});

// Action creators
export const { setIsInitialized } = appSlice.actions;

// Selectors
export const selectIsInitialized = (state: RootState) =>
  state.app.isInitialized;
export const selectAccessError = (state: RootState) => state.app.accessError;

export default appSlice.reducer;
