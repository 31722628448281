import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AppDispatch } from 'App/store';

import { logout } from './logout';

export interface UnauthorizedPageProps {
  title: string;
  content: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  grid: {
    minHeight: '100vh',
  },
}));

// Customizable page to show when a user is unauthorized.
export function UnauthorizedPage({ title, content }: UnauthorizedPageProps) {
  const dispatch: AppDispatch = useDispatch();
  const classes = useStyles();

  return (
    <Container maxWidth="xs" component="main">
      <Grid container alignItems="center" className={classes.grid}>
        <Box width="100%">
          <Typography
            component="h2"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            component="p"
            gutterBottom
          >
            {content}
          </Typography>
          <Box mt={4} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => dispatch(logout())}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}
