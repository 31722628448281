import { AxiosError } from 'axios';

import { axiosInstance as instance } from './axiosInstance';

export type ErrorCode = 'unauthorized' | 'forbidden';

export type ForbiddenErrorDetail =
  | 'accountSuspended'
  | 'passwordChangeRequired'
  | 'userDisabled'
  | 'other';

export type ErrorDetail = ForbiddenErrorDetail;

export type ApiErrorCallback = (code: ErrorCode, detail?: ErrorDetail) => void;

// Installed interceptor
let interceptor: number | null = null;

// Set a callback to observe certain API errors.
export function setApiErrorCallback(callback: ApiErrorCallback | null) {
  // Uninstall previous interceptor
  if (interceptor !== null) {
    instance.interceptors.response.eject(interceptor);
    interceptor = null;
  }

  if (callback === null) {
    return;
  }

  // Parse response and call callback
  const onError = (error: AxiosError) => {
    const status = error.response?.status;
    const message: string | undefined = error.response?.data?.message;

    // TODO: Consider extending the backend response to avoid parsing
    // user-readable messages to get error details

    if (status === 401) {
      callback('unauthorized');
    } else if (status === 403) {
      if (message?.includes('disabled')) {
        // User is not enabled
        callback('forbidden', 'userDisabled');
      } else if (message?.includes('password')) {
        // Password change is required
        callback('forbidden', 'passwordChangeRequired');
      } else if (message?.includes('suspended')) {
        // Account is suspended
        callback('forbidden', 'accountSuspended');
      } else {
        callback('forbidden', 'other');
      }
    }

    return Promise.reject(error);
  };

  // Install new interceptor
  interceptor = instance.interceptors.response.use(undefined, onError);
}
