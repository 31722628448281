import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

import accountReducer from 'Features/Account/accountSlice';
import appReducer, { AppState } from 'Features/App/appSlice';
import authReducer, { AuthState } from 'Features/Auth/authSlice';
import localAuthReducer, { LocalAuthState } from 'Features/Auth/localAuthSlice';
import drawerReducer from 'Features/Main/drawerSlice';
import userReducer from 'Features/User/userSlice';

const appPersistConfig = {
  key: 'app',
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['isInitialized'],
};

const authPersistConfig = {
  key: 'auth',
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
};

const localAuthPersistConfig = {
  key: 'localAuth',
  storage: localStorage,
  stateReconciler: autoMergeLevel2,
};

// TODO: May be able to remove typing of persistReducer once
// https://github.com/rt2zz/redux-persist/pull/1085 is released
const rootReducer = combineReducers({
  account: accountReducer,
  app: persistReducer<AppState>(appPersistConfig, appReducer),
  auth: persistReducer<AuthState>(authPersistConfig, authReducer),
  localAuth: persistReducer<LocalAuthState>(
    localAuthPersistConfig,
    localAuthReducer
  ),
  user: userReducer,
  drawer: drawerReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
