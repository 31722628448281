import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  AppBar,
  AppBarProps,
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  Popover,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import LisnrLogo from 'Assets/svg/lisnr-logo-lockup.svg';
import md5 from 'md5';

import { AppDispatch } from 'App/store';
import { selectAccount } from 'Features/Account/accountSlice';
import { logout } from 'Features/Auth/logout';
import { setDrawerOpen } from 'Features/Main/drawerSlice';
import {
  selectEmail,
  selectFullName,
  selectIsAccountAdmin,
} from 'Features/User/userSlice';

export interface MenuAppBarProps extends AppBarProps {
  drawer: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: '130px',
      width: '100%',
    },
    iconButton: {
      padding: 0,
    },
    avatar: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[400],
      height: '30px',
      width: '30px',
    },
    avatarLarge: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[400],
      height: '75px',
      width: '75px',
    },
    popover: {
      marginTop: '30px',
    },
    breakWord: {
      wordBreak: 'break-all',
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
  })
);

// Based on https://material-ui.com/components/app-bar/#app-bar-with-menu
export const MenuAppBar = (props: MenuAppBarProps) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const email = useSelector(selectEmail);
  const isAccountAdmin = useSelector(selectIsAccountAdmin);
  const fullName = useSelector(selectFullName);
  const account = useSelector(selectAccount);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserProfile = () => {
    handleClose();
    history.push('/user-profile');
  };

  const handleAccountInfo = () => {
    handleClose();
    history.push('/account');
  };

  const handleLogOut = () => {
    handleClose();
    dispatch(logout());
  };

  const openDrawer = () => {
    dispatch(setDrawerOpen(true));
  };

  return (
    <AppBar {...(({ drawer, ...originalProps }) => originalProps)(props)}>
      <Toolbar>
        <Box display="flex" flexGrow={1} justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {props.drawer ? (
              <Hidden mdUp implementation="css">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={openDrawer}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            ) : null}
            <Typography variant="h6">
              <Link to="/">
                <Box display="flex">
                  <img
                    alt="LISNR Logo"
                    src={LisnrLogo}
                    className={classes.logo}
                  />
                </Box>
              </Link>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className={classes.iconButton}
            >
              <Avatar
                className={classes.avatar}
                src={
                  'https://www.gravatar.com/avatar/' +
                  md5(email ? email : 'dummy') +
                  '?d=mp'
                }
              />
            </IconButton>
            <Popover
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
              className={classes.popover}
            >
              <Box
                width="300px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box display="flex" alignItems="center" flexDirection="column">
                  <Box my={2}>
                    <Avatar
                      className={classes.avatarLarge}
                      src={
                        'https://www.gravatar.com/avatar/' +
                        md5(email ? email : 'dummy') +
                        '?d=mp'
                      }
                    />
                  </Box>
                  <Box textAlign="center" mx={2}>
                    <Typography
                      component="p"
                      variant="h5"
                      className={classes.breakWord}
                    >
                      {fullName}
                    </Typography>
                  </Box>
                  {isAccountAdmin ? (
                    <Typography variant="body2" color="textSecondary">
                      Admin
                    </Typography>
                  ) : null}
                </Box>
                <Box my={1} textAlign="center">
                  <Button
                    color="primary"
                    size="small"
                    onClick={handleUserProfile}
                  >
                    User Profile
                  </Button>
                </Box>
                <Divider />
                <Box textAlign="center">
                  <Box my={1} mx={2}>
                    <Typography
                      color="textSecondary"
                      className={classes.breakWord}
                    >
                      Account: {account.name}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Button
                      color="primary"
                      size="small"
                      onClick={handleAccountInfo}
                    >
                      Manage Account
                    </Button>
                  </Box>
                </Box>
                <Divider />
                <Box my={2} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogOut}
                  >
                    Log Out
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
