import { getAccount, GetAccountResponse } from './account';
import {
  changePassword,
  ChangePasswordRequest,
  ChangePasswordResponse,
  login,
  LoginRequest,
  LoginResponse,
  logout,
  LogoutResponse,
  requestPasswordReset,
  RequestPasswordResetRequest,
  RequestPasswordResetResponse,
  resetPassword,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from './auth';
import { axiosInstance as instance } from './axiosInstance';
import {
  Account,
  ApiToken,
  AppToken,
  Platform,
  Product,
  Profile,
  Project,
  Sdk,
  SdkToken,
  SdkTokenV4,
  SdkTokenV5,
  SubscriptionState,
  User,
} from './dataTypes';
import {
  createApiToken,
  CreateApiTokenResponse,
  deleteApiToken,
  deleteProject,
  getApiTokens,
  GetApiTokensRequest,
  GetApiTokensResponse,
  getAppToken,
  GetAppTokenResponse,
  getProject,
  GetProjectResponse,
  getProjects,
  GetProjectsResponse,
  getSdkTokens,
  GetSdkTokensRequest,
  GetSdkTokensResponse,
} from './project';
import { getSdks, GetSdksRequest, GetSdksResponse } from './sdk';
import {
  getUser,
  GetUserResponse,
  getUsers,
  GetUsersRequest,
  GetUsersResponse,
  updateUser,
  UpdateUserRequest,
  UpdateUserResponse,
} from './user';

//
// General API call types
//

export interface ErrorResponse {
  code: string;
  message: string;
}

//
// API call configuration
//

export function setAuthorizationToken(token: string | null) {
  if (token !== null) {
    instance.defaults.headers.common['Authorization'] = `JWT ${token}`;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
}

//
// Re-exports
//

// Data types
export type {
  Account,
  AppToken,
  ApiToken,
  SubscriptionState,
  Product,
  Profile,
  Project,
  Sdk,
  SdkToken,
  SdkTokenV4,
  SdkTokenV5,
  Platform,
  User,
};

// account
export type { GetAccountResponse };
export { getAccount };

// auth
export type {
  LoginRequest,
  LoginResponse,
  LogoutResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  RequestPasswordResetRequest,
  RequestPasswordResetResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
};
export { login, logout, changePassword, requestPasswordReset, resetPassword };

// project
export type {
  CreateApiTokenResponse,
  GetProjectResponse,
  GetProjectsResponse,
  GetApiTokensRequest,
  GetAppTokenResponse,
  GetApiTokensResponse,
  GetSdkTokensRequest,
  GetSdkTokensResponse,
};
export {
  createApiToken,
  deleteApiToken,
  deleteProject,
  getApiTokens,
  getAppToken,
  getProject,
  getProjects,
  getSdkTokens,
};

// sdk
export type { GetSdksRequest, GetSdksResponse };
export { getSdks };

// user
export type {
  GetUserResponse,
  GetUsersRequest,
  GetUsersResponse,
  UpdateUserRequest,
  UpdateUserResponse,
};
export { getUser, getUsers, updateUser };
