import React from 'react';
import { Redirect } from 'react-router-dom';

// Redirect to the Not Found page.
export const RouteNotFound = () => (
  <Redirect
    to={{
      state: {
        notFoundError: true,
      },
    }}
  />
);
