import * as api from 'Api';

const displayName = {
  legacy: 'LISNR Legacy',
  point: 'LISNR Point',
  radius: 'LISNR Radius',
};

// Get a user-readable name for a product name returned by the API.
export const productDisplayName = (product: api.Product) => {
  return displayName[product] ?? 'Unknown';
};
