import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from 'Api';
import { RootState } from 'App/rootReducer';

import { loggedOut } from './logout';

export interface LoginRequest {
  username: string;
  password: string;
  stayLoggedIn: boolean;
}

export interface LoginResult {
  token: string;
}

/**
 * Log in to the Portal.
 */
export const login = createAsyncThunk<
  LoginResult,
  LoginRequest,
  {
    rejectValue: api.ErrorResponse;
  }
>('auth/login', async ({ username, password }, { rejectWithValue }) => {
  let token = null;

  try {
    const { data } = await api.login({ username, password });
    token = data.token;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data as api.ErrorResponse);
  }

  return { token };
});

export interface AuthState {
  token: string | null;
}

const initialState: AuthState = {
  token: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Handle setting token from local storage
    initTokenFromLocalStorage: (
      state,
      { payload }: PayloadAction<AuthState['token']>
    ) => {
      state.token = payload;
    },
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.token = payload.token;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.token = null;
    });

    // Logged out
    builder.addCase(loggedOut, () => {
      return initialState;
    });
  },
});

// Action creators
export const { initTokenFromLocalStorage } = authSlice.actions;

// Selectors
export const selectToken = (state: RootState) => state.auth.token;

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.token !== null;

export const selectIsAuthorized = ({
  account: { subscriptionState },
  user: { passwordChangeRequired, userEnabled },
}: RootState): boolean => {
  return (
    passwordChangeRequired === false &&
    userEnabled === true &&
    (subscriptionState === 'live' || subscriptionState === 'trial')
  );
};

export default authSlice.reducer;
