import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Container,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import brown from '@material-ui/core/colors/brown';
import { makeStyles } from '@material-ui/core/styles';
import {
  CellValue,
  ColDef,
  DataGrid,
  GridOverlay,
  ValueFormatterParams,
  ValueGetterParams,
} from '@material-ui/data-grid';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import { debounce } from 'lodash';

import * as api from 'Api';
import { getAllPages } from 'Api/pagination';
import { useIsMounted } from 'Hooks/useIsMounted';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginBottom: theme.spacing(1),
  },
  isEnabled: {
    color: theme.palette.text.secondary,
  },
  noneCell: {
    color: theme.palette.text.disabled,
    fontStyle: 'italic',
  },
}));

// Context to enable accessing search term in NoRowsOverlay.
// See https://github.com/mui-org/material-ui-x/issues/641
const SearchContext = React.createContext<string | null>(null);

// Custom table header shown when a search is active.
function SearchResultsHeader() {
  return (
    <Box bgcolor={brown[100]} padding={1}>
      Search results:
    </Box>
  );
}

// Custom grid overlay shown when the table is empty. In practice this happens
// only when a search is active and no users match.
function NoRowsOverlay() {
  const search = useContext(SearchContext);
  return (
    <GridOverlay style={{ padding: '8px' }}>
      {search === null || search === ''
        ? 'No users found'
        : `No users found for "${search}"`}
    </GridOverlay>
  );
}

// For the given row, get the user's full name constructed from their first and
// last names.
function getFullName(params: ValueGetterParams) {
  const firstName = params.getValue('first_name');
  const lastName = params.getValue('last_name');
  if (!firstName || !lastName || firstName === '' || lastName === '') {
    return null;
  }
  return `${firstName} ${lastName}`;
}

// String comparator that places null elements at the end.
function sortWithNullLast(v1: string | null, v2: string | null) {
  if (v1 === null) {
    return v2 === null ? 0 : 1;
  }
  if (v2 === null) {
    return -1;
  }
  return v1.localeCompare(v2);
}

export function ManageUsers() {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [users, setUsers] = useState<api.User[] | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const classes = useStyles();

  const debounceSearch = debounce((event) => {
    setSearch(event.target.value);
  }, 500);
  const handleSearch = useCallback(debounceSearch, [debounceSearch]);

  // Render a cell. Fall back to "None" if its value is null.
  function renderNoneCell(value: CellValue) {
    let result;
    if (value === null || value === undefined) {
      result = <span className={classes.noneCell}>None</span>;
    } else {
      result = <>{value}</>;
    }
    return result;
  }

  // Render a cell that has a boolean value as a checkmark.
  function renderBooleanCell(params: ValueFormatterParams) {
    return (
      <>
        {(params.value as boolean) && (
          <CheckIcon className={classes.isEnabled} />
        )}
      </>
    );
  }

  const columns: ColDef[] = [
    { field: 'first_name', hide: true },
    { field: 'last_name', hide: true },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const fullName1 = getFullName(cellParams1);
        const fullName2 = getFullName(cellParams2);
        return sortWithNullLast(fullName1, fullName2);
      },
      renderCell: (params: ValueFormatterParams) => {
        const fullName = getFullName(params);
        return renderNoneCell(fullName);
      },
    },
    {
      field: 'username',
      headerName: 'Username',
      width: 200,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return ((v1 as string) ?? '').localeCompare(
          (v2 as string) ?? '',
          undefined,
          {
            numeric: true,
          }
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email Address',
      width: 250,
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return sortWithNullLast(v1 as string, v2 as string);
      },
      renderCell: (params: ValueFormatterParams) => {
        return renderNoneCell(params.value);
      },
    },
    {
      field: 'is_account_admin',
      headerName: 'Administrator',
      width: 150,
      renderCell: renderBooleanCell,
    },
    {
      field: 'is_enabled',
      headerName: 'Enabled',
      width: 150,
      renderCell: renderBooleanCell,
    },
  ];

  // Load users for account
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    async function fetchUsers() {
      let cancelled = false;
      let errorMessage = null;

      const params: api.GetUsersRequest = {};
      if (search !== null && search !== '') {
        params.search = search;
      }

      try {
        const data = await getAllPages(
          (request) => api.getUsers(request, { cancelToken: source.token }),
          params
        );
        setUsers(data);
      } catch (err) {
        cancelled = axios.isCancel(err);
        errorMessage =
          err.response?.data.message ?? err.message ?? 'Unknown error';
      }

      if (!cancelled && isMounted.current) {
        setErrorMessage(errorMessage);
        setLoading(false);
      }
    }

    setUsers([]);
    setLoading(true);
    fetchUsers();

    return () => {
      source.cancel();
    };
  }, [isMounted, search]);

  let content = null;
  let headerContent = null;

  if (errorMessage) {
    content = <Typography color="error">Error: {errorMessage}</Typography>;
  } else {
    content = (
      <Box height={800} width="100%">
        <Box display="flex" height="100%">
          <Box flexGrow={1}>
            <SearchContext.Provider value={search}>
              <DataGrid
                rows={users ?? []}
                columns={columns}
                // Disable column virtualization to ensure all columns are rendered in tests
                columnBuffer={columns.length}
                loading={loading}
                sortingOrder={['asc', 'desc']}
                sortModel={[{ field: 'name', sort: 'asc' }]}
                disableSelectionOnClick
                components={{
                  header:
                    search !== null && search !== ''
                      ? SearchResultsHeader
                      : undefined,
                  noRowsOverlay: NoRowsOverlay,
                }}
              />
            </SearchContext.Provider>
          </Box>
        </Box>
      </Box>
    );

    // TODO: Could handle Enter keypress and coordinate with debounce
    headerContent = (
      <TextField
        variant="outlined"
        size="small"
        id="search"
        placeholder="Search..."
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          ),
        }}
      />
    );
  }

  return (
    <Container maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <Link component={RouterLink} color="inherit" to="/account">
          Account Information
        </Link>
        <Typography color="textPrimary">Manage Users</Typography>
      </Breadcrumbs>
      <Box mb={1} textAlign="right">
        {headerContent}
      </Box>
      {content}
    </Container>
  );
}
