import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import { Routes } from './Routes';
import { SessionInitializer } from './SessionInitializer';
import { SessionLoading } from './SessionLoading';
import { theme } from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HelmetProvider>
        <Helmet defaultTitle="LISNR Portal" titleTemplate="LISNR Portal - %s" />
        <SessionInitializer loadingIndicator={<SessionLoading />}>
          <Routes />
        </SessionInitializer>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
