import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import LabelIcon from '@material-ui/icons/Label';
import SpeakerIcon from '@material-ui/icons/Speaker';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import APITokens from 'Assets/svg/api-tokens.svg';
import AppToken from 'Assets/svg/app-token.svg';
import SDKTokens from 'Assets/svg/sdk-tokens.svg';
import ToneCreator from 'Assets/svg/tone-creator.svg';
import UserAccess from 'Assets/svg/user-access.svg';

import * as api from 'Api';
import { theme } from 'App/theme';

export interface ProjectAction {
  name: string;
  route: string;
  icon: React.ReactNode;
  drawerOnly?: boolean;
  mediaImage?: string;
  cardStyle?: object;
}

// Get the actions available for a specific project.
export function getProjectActions(
  project: api.Project,
  isAccountAdmin: boolean | null
) {
  const actions: ProjectAction[] = [];

  let hasSdkTokens = false;
  let hasApiTokens = false;
  let hasAppToken = false;
  let hasToneCreator = false;

  switch (project.sdk_type) {
    case 'radius':
      hasSdkTokens = true;
      hasApiTokens = true;
      hasToneCreator = true;
      break;
    case 'point':
      hasSdkTokens = true;
      break;
    case 'legacy':
      hasApiTokens = true;
      hasAppToken = true;
      hasToneCreator = true;
      break;
    default:
      break;
  }

  actions.push({
    name: 'SDK Download',
    route: '/sdks',
    icon: <GetAppIcon />,
    drawerOnly: true,
  });

  if (hasSdkTokens) {
    actions.push({
      name: 'SDK Tokens',
      route: `/project/${project.id}/sdk-tokens`,
      icon: <LabelIcon />,
      mediaImage: SDKTokens,
      cardStyle: { background: theme.palette.primary.main },
    });
  }

  if (hasApiTokens) {
    actions.push({
      name: 'API Tokens',
      route: `/project/${project.id}/api-tokens`,
      icon: <LabelIcon />,
      mediaImage: APITokens,
      cardStyle: { background: theme.palette.secondary.main },
    });
  }

  if (hasAppToken) {
    actions.push({
      name: 'App Token',
      route: `/project/${project.id}/app-token`,
      icon: <LabelIcon />,
      mediaImage: AppToken,
      cardStyle: { background: theme.palette.lightGreen.main },
    });
  }

  if (hasToneCreator) {
    actions.push({
      name: 'Tone Creator',
      route: `/project/${project.id}/tone-creator`,
      icon: <SpeakerIcon />,
      mediaImage: ToneCreator,
      cardStyle: { background: theme.palette.darkBlue.main },
    });
  }

  if (isAccountAdmin) {
    actions.push({
      name: 'User Access',
      route: `/project/${project.id}/user-access`,
      icon: <SupervisedUserCircleIcon />,
      mediaImage: UserAccess,
      cardStyle: { background: theme.palette.lightBlue.main },
    });
  }

  return actions;
}

// TODO: getProjectActions test
