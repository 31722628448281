import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

export interface ConfirmationDialogProps {
  // Whether the dialog is open.
  open: boolean;
  // Title of the dialog.
  titleText: string;
  // Main text content of the dialog.
  contentText: React.ReactNode;
  // Text on the confirmation button.
  confirmButtonText: string;
  // Text that the user is required to type to perform the action.
  // If omitted, don't require the user to enter confirmation text.
  requiredConfirmationText?: string;
  // Dialog cancel callback.
  onCancel: () => void;
  // Dialog confirm callback.
  onConfirm: () => void;
}

// Dialog that forces the user to confirm that they intend to perform a
// dangerous action.
export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {
    open,
    titleText,
    contentText,
    confirmButtonText,
    requiredConfirmationText,
    onCancel,
    onConfirm,
  } = props;
  const [confirmationText, setConfirmationText] = useState('');

  function handleCancel() {
    onCancel();
    setConfirmationText('');
  }

  async function handleConfirm() {
    await onConfirm();
    setConfirmationText('');
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentText}
        </DialogContentText>
        {requiredConfirmationText && (
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            autoFocus
            onChange={(event) => setConfirmationText(event.target.value)}
            onKeyPress={(event) => {
              if (
                event.key === 'Enter' &&
                confirmationText === requiredConfirmationText
              ) {
                handleConfirm();
              }
            }}
            value={confirmationText}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleConfirm}
          disabled={
            requiredConfirmationText !== undefined &&
            requiredConfirmationText !== confirmationText
          }
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
