import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'App/rootReducer';

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    open: false,
  },
  reducers: {
    setDrawerOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

// Action creators
export const { setDrawerOpen } = drawerSlice.actions;

// Selectors
export const selectDrawerState = (state: RootState) => state.drawer.open;

export default drawerSlice.reducer;
