const MIN_LENGTH = 6;
const MAX_LENGTH = 25;

const numbers = /[0-9]/;
const specialCharacters = /[!@#$%^&*_]/;
const disallowedCharacters = /[^A-Za-z0-9!@#$%^&*_]/;

// Validate that a password meets the length and complexity requirements. Throws
// an error with a detailed message if not.
export const validatePassword = (password: string): void => {
  if (password.length < MIN_LENGTH) {
    throw new Error('Password is too short.');
  }

  if (password.length > MAX_LENGTH) {
    throw new Error('Password is too long.');
  }

  if (!numbers.test(password)) {
    throw new Error('Password does not contain a number.');
  }

  if (!specialCharacters.test(password)) {
    throw new Error('Password does not contain a special character.');
  }

  if (disallowedCharacters.test(password)) {
    throw new Error('Password contains a character that is not allowed.');
  }
};
