import { selectIsAuthorized } from 'Features/Auth/authSlice';
import { logout } from 'Features/Auth/logout';

import { forbiddenError, unauthorizedError } from './errorActions';
import { AppThunk } from './store';

// Thunk to dispatch an unauthorized error when authorized. Log out when not
// authorized.
export const unauthorizedErrorIfAuthorized = (): AppThunk => {
  return (dispatch, getState) => {
    const isAuthorized = selectIsAuthorized(getState());
    if (isAuthorized) {
      dispatch(unauthorizedError());
    } else {
      dispatch(logout());
    }
  };
};

// Thunk to dispatch a forbidden error when authorized. Log out when not
// authorized.
export const forbiddenErrorIfAuthorized = (): AppThunk => {
  return (dispatch, getState) => {
    const isAuthorized = selectIsAuthorized(getState());
    if (isAuthorized) {
      dispatch(forbiddenError());
    } else {
      dispatch(logout());
    }
  };
};
