import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import LisnrPointIcon from 'Assets/svg/lisnr-point-icon.svg';
import LisnrRadiusIcon from 'Assets/svg/lisnr-radius-icon.svg';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import * as api from 'Api';
import { getAllPages } from 'Api/pagination';
import { AppDispatch } from 'App/store';
import { ConfirmationDialog } from 'Components/ConfirmationDialog';
import { selectIsAccountAdmin } from 'Features/User/userSlice';
import { useIsMounted } from 'Hooks/useIsMounted';
import { productDisplayName } from 'Utils/productDisplayName';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  progress: {
    color: '#fff8dc',
  },
  gridItemCard: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '160px',
    },
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiCardActionArea-root': {
      flex: 1,
    },
    justifyContent: 'center',
    height: '100%',
  },
  createProjectButton: {
    width: '100%',
    height: '100%',
    textTransform: 'none',
  },
  createProjectButtonTypography: {
    color: theme.palette.primary.main,
  },
  productTypeLogo: {
    maxWidth: theme.typography.h6.fontSize,
    marginRight: theme.spacing(1),
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
}));

function formatCreatedAt(date: string) {
  return dayjs(date).format('MM/DD/YY HH:mma');
}

interface ProjectGridProps {
  projects: api.Project[];
  // Callback called when user clicks button to delete a project. Omit to hide
  // the delete button.
  onDelete?: (project: api.Project) => void;
  // Callback called when user clicks button to create a project. Omit to hide
  // the create button.
  onCreate?: () => void;
}

function ProjectGrid({ projects, onDelete, onCreate }: ProjectGridProps) {
  const classes = useStyles();

  if (projects.length === 0) {
    return onCreate ? (
      <Grid container spacing={3} className={classes.justifyCenter}>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            elevation={2}
            onClick={() => onCreate()}
            className={classes.gridItemCard}
          >
            <Button className={classes.createProjectButton}>
              <Box className={classes.createProjectButtonTypography}>
                <Typography variant="h1" component="p">
                  +
                </Typography>
                <Box mb={2}>
                  <Typography variant="body2">Create Project</Typography>
                </Box>
              </Box>
            </Button>
          </Card>
        </Grid>
      </Grid>
    ) : (
      <Typography variant="body1" align="center">
        No projects available.
      </Typography>
    );
  }

  return (
    <Grid container spacing={3}>
      {onCreate && (
        <Grid item xs={12} sm={6} md={4}>
          <Card
            elevation={2}
            onClick={() => onCreate()}
            className={classes.gridItemCard}
          >
            <Button className={classes.createProjectButton}>
              <Box className={classes.createProjectButtonTypography}>
                <Typography variant="h1" component="p">
                  +
                </Typography>
                <Box mb={2}>
                  <Typography variant="body2">Create Project</Typography>
                </Box>
              </Box>
            </Button>
          </Card>
        </Grid>
      )}
      {projects.map((project) => (
        <Grid key={project.id} item xs={12} sm={6} md={4}>
          <Card elevation={2} className={classes.gridItemCard}>
            <CardActionArea component={Link} to={`/project/${project.id}`}>
              <Box m={2}>
                <Typography
                  variant="h6"
                  className={classes.breakWord}
                  gutterBottom
                >
                  {project.name}
                </Typography>
                <Box display="flex" alignItems="center">
                  {project.sdk_type !== 'legacy' ? (
                    <img
                      className={classes.productTypeLogo}
                      alt={productDisplayName(project.sdk_type)}
                      src={
                        {
                          point: LisnrPointIcon,
                          radius: LisnrRadiusIcon,
                          legacy: undefined,
                        }[project.sdk_type]
                      }
                    />
                  ) : null}
                  <Typography variant="subtitle1">
                    {productDisplayName(project.sdk_type)}
                  </Typography>
                </Box>
              </Box>
            </CardActionArea>
            <CardActions>
              <Box flex="1" pl={1}>
                <Typography variant="body2" color="textSecondary">
                  Created: {formatCreatedAt(project.created_at)}
                </Typography>
              </Box>
              {onDelete && (
                <IconButton
                  aria-label="delete"
                  onClick={() => onDelete(project)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export function ProjectList() {
  const dispatch: AppDispatch = useDispatch();
  const isMounted = useIsMounted();
  const isAccountAdmin = useSelector(selectIsAccountAdmin);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [projects, setProjects] = useState<api.Project[] | null>(null);
  const [projectToDelete, setProjectToDelete] = useState<api.Project | null>(
    null
  );
  const [isDeletingProject, setIsDeletingProject] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  // Delete a project
  async function deleteProject(projectToDelete: api.Project) {
    try {
      await api.deleteProject(projectToDelete.id);
      if (!isMounted.current) {
        return;
      }

      setProjects((projects) => {
        return projects!.filter((project) => project.id !== projectToDelete.id);
      });

      enqueueSnackbar('Project deleted', { variant: 'success' });
    } catch (err) {
      if (!isMounted.current) {
        return;
      }
      enqueueSnackbar('Error deleting project', { variant: 'error' });
    }
  }

  // Delete confirmation dialog cancel handler
  function handleDialogCancel() {
    setDialogOpen(false);
    setProjectToDelete(null);
  }

  // Delete confirmation dialog confirm handler
  async function handleDialogConfirm() {
    setDialogOpen(false);
    setIsDeletingProject(true);

    if (projectToDelete !== null) {
      await deleteProject(projectToDelete);
      setProjectToDelete(null);
    }

    setIsDeletingProject(false);
  }

  // Load projects
  useEffect(() => {
    async function fetchProjects() {
      let errorMessage = null;

      try {
        const projects = await getAllPages(api.getProjects, {});
        if (isMounted.current) {
          setProjects(projects);
        }
      } catch (err) {
        errorMessage =
          err.response?.data.message ?? err.message ?? 'Unknown error';
      }

      if (isMounted.current) {
        setErrorMessage(errorMessage);
        setLoading(false);
      }
    }

    fetchProjects();
  }, [dispatch, isMounted]);

  let content = null;

  if (loading) {
    content = (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else if (errorMessage) {
    content = <Typography color="error">Error: {errorMessage}</Typography>;
  } else if (projects !== null) {
    content = (
      <ProjectGrid
        projects={projects}
        onDelete={
          isAccountAdmin
            ? (project) => {
                setProjectToDelete(project);
                setDialogOpen(true);
              }
            : undefined
        }
        onCreate={isAccountAdmin ? () => {} : undefined}
      />
    );
  }

  return (
    <>
      <Container maxWidth="lg">
        <Box mt={3} mb={6}>
          <Typography variant="h3" align="center">
            Your Projects
          </Typography>
        </Box>
        {content}
      </Container>
      <ConfirmationDialog
        open={dialogOpen}
        onCancel={handleDialogCancel}
        onConfirm={handleDialogConfirm}
        titleText="Delete Project?"
        contentText={
          <>
            This action <b>cannot</b> be undone. This will permanently delete
            the <b>{projectToDelete?.name}</b> project and all its tokens.
            <br />
            <br />
            Please type <b>delete</b> to confirm.
          </>
        }
        confirmButtonText="Delete Project"
        requiredConfirmationText="delete"
      />
      <Backdrop className={classes.backdrop} open={isDeletingProject}>
        <CircularProgress className={classes.progress} />
      </Backdrop>
    </>
  );
}
