import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardHeader,
  Container,
  Grid,
} from '@material-ui/core';
import { CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as api from 'Api';
import { selectIsAccountAdmin } from 'Features/User/userSlice';

import { getProjectActions } from './getProjectActions';

const useStyles = makeStyles((theme) => ({
  media: {
    height: '175px',
    [theme.breakpoints.down('sm')]: {
      height: '150px',
    },
    backgroundSize: 'contain',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    backgroundOrigin: 'content-box',
  },
  card: {
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  cardHeader: {
    borderTop: '1px solid ' + theme.palette.common.white,
  },
}));

export interface ProjectProps {
  project: api.Project;
}

export function Project({ project }: ProjectProps) {
  const classes = useStyles();
  const isAccountAdmin = useSelector(selectIsAccountAdmin);
  const actions = getProjectActions(project, isAccountAdmin);
  const validActions = actions.filter((action) => !action.drawerOnly);

  return (
    <Container disableGutters>
      <Grid container spacing={3}>
        {validActions.map((action) => (
          <Grid key={action.name} item xs={12} sm={6} lg={4}>
            <Card
              elevation={2}
              className={classes.card}
              style={action.cardStyle}
            >
              <CardActionArea component={Link} to={action.route}>
                {action.mediaImage ? (
                  <CardMedia
                    className={classes.media}
                    image={action.mediaImage}
                  />
                ) : undefined}
                <CardHeader
                  className={classes.cardHeader}
                  title={action.name}
                  titleTypographyProps={{
                    variant: 'h6',
                  }}
                />
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

// TODO: Project test
