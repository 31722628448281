import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { useSnackbar } from 'notistack';

import * as api from 'Api';
import { LoadingButton } from 'Components/LoadingButton';
import { PasswordTextField } from 'Components/PasswordTextField';
import { validatePassword } from 'Utils/validatePassword';

const useStyles = makeStyles((theme) => ({
  grid: {
    minHeight: '100vh',
  },
  form: {
    marginTop: theme.spacing(1),
  },
  errorIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(1),
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  togglePassword: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Params {
  token: string;
}

interface Inputs {
  password: string;
}

export function ResetPasswordForm() {
  const { control, errors, handleSubmit } = useForm<Inputs>();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const location = useLocation();
  const { token } = useParams<Params>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const searchParams = new URLSearchParams(location.search);
  let email = searchParams.get('e');
  if (email !== null) {
    email = email.replace(/ /g, '+');
  }

  async function onSubmit({ password }: Inputs) {
    setErrorMessage(null);
    setIsSubmitting(true);

    try {
      const { data } = await api.resetPassword({ token, password });
      enqueueSnackbar(data.message, { variant: 'success' });
      history.push('/login');
    } catch (error) {
      setErrorMessage('Unable to change your password. Please try again.');
    }

    setIsSubmitting(false);
  }

  return (
    <>
      <Helmet title="Reset Password" />
      <Box margin={4}>
        <Typography variant="h3" gutterBottom>
          Reset Password
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
          gutterBottom
        >
          {email !== null
            ? `Enter a new password for ${email}. `
            : 'Enter a new password. '}
          Your password must have between 6 and 25 characters, be alphanumeric,
          and include at least one special character (!@#$%^&*_) and one number.
          No other special characters are allowed.
        </Typography>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
        >
          <Box>
            <Controller
              as={PasswordTextField}
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: 'Required',
                validate: (password) => {
                  let message;
                  try {
                    validatePassword(password);
                  } catch (error) {
                    message = error.message;
                  }
                  return message ?? true;
                },
              }}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              disabled={isSubmitting}
              fullWidth
              required
              variant="outlined"
              margin="normal"
              id="password"
              label="Password"
              autoComplete="new-password"
            />
          </Box>
          <Box margin="normal">
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              disabled={isSubmitting}
              loading={isSubmitting}
              className={classes.submit}
            >
              Set Password
            </LoadingButton>
          </Box>
          {errorMessage && (
            <Typography
              variant="body2"
              color="error"
              className={classes.errorMessage}
            >
              <ErrorIcon className={classes.errorIcon} />
              Error: {errorMessage}
            </Typography>
          )}
        </form>
      </Box>
    </>
  );
}
