import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorIcon from '@material-ui/icons/Error';
import { useSnackbar } from 'notistack';

import * as api from 'Api';
import { LoadingButton } from 'Components/LoadingButton';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
  },
  goBackButton: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  errorIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(1),
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Inputs {
  username: string;
}

export function ForgotPasswordForm() {
  const { control, errors, handleSubmit } = useForm<Inputs>();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  async function onSubmit({ username }: Inputs) {
    setErrorMessage(null);
    setIsSubmitting(true);

    try {
      const { data } = await api.requestPasswordReset({ username });
      enqueueSnackbar(data.message, { variant: 'success' });
      history.push('/login');
    } catch (error) {
      const message = error.message ?? 'Unknown error';
      setErrorMessage(message);
    }

    setIsSubmitting(false);
  }

  return (
    <>
      <Helmet title="Forgot Password" />
      <Box margin={4}>
        <Typography variant="h3" gutterBottom>
          Forgot Password
        </Typography>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
        >
          <Box>
            <Controller
              as={TextField}
              name="username"
              control={control}
              defaultValue=""
              rules={{ required: 'Required' }}
              error={Boolean(errors.username)}
              helperText={errors.username?.message}
              disabled={isSubmitting}
              fullWidth
              autoFocus
              required
              variant="outlined"
              margin="normal"
              id="forgot-username"
              label="Username or email"
            />
          </Box>
          <Box margin="normal">
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              disabled={isSubmitting}
              loading={isSubmitting}
              className={classes.submit}
            >
              Send recovery email
            </LoadingButton>
          </Box>
          <Button
            component={Link}
            to="/login"
            color="primary"
            size="small"
            className={classes.goBackButton}
            startIcon={<ArrowBackIcon />}
          >
            Go back to Log In
          </Button>
          {errorMessage && (
            <Typography
              variant="body2"
              color="error"
              className={classes.errorMessage}
            >
              <ErrorIcon className={classes.errorIcon} />
              Error: {errorMessage}
            </Typography>
          )}
        </form>
      </Box>
    </>
  );
}
