import React, { useRef } from 'react';
import { Link, Route, RouteChildrenProps } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Box, Button, Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Facebook, Instagram, LinkedIn, Twitter } from '@material-ui/icons';
import LisnrLogo from 'Assets/svg/lisnr-logo-lockup.svg';

import { ForgotPasswordForm } from './ForgotPasswordForm';
import { LoginForm } from './LoginForm';
import { ResetPasswordForm } from './ResetPasswordForm';

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: '370px',
    width: '100%',
  },

  socialIcon: {
    color: theme.palette.grey[700],
    fontSize: '3rem',
    margin: '-5px 5px -5px -5px',
  },

  paper: {
    overflow: 'hidden',
    minHeight: '525px',
    position: 'relative',
  },

  left: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  leftEnter: {
    transform: 'translateX(-100%)',
  },
  leftEnterActive: {
    transform: 'translateX(0%)',
    transition: 'all 300ms',
  },
  leftExit: {
    transform: 'translateX(0%)',
  },
  leftExitActive: {
    transform: 'translateX(-100%)',
    transition: 'all 300ms',
  },

  right: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  rightEnter: {
    transform: 'translateX(100%)',
  },
  rightEnterActive: {
    transform: 'translateX(0%)',
    transition: 'all 300ms',
  },
  rightExit: {
    transform: 'translateX(0%)',
  },
  rightExitActive: {
    transform: 'translateX(100%)',
    transition: 'all 300ms',
  },
}));

export function Login() {
  const loginRef = useRef(null);
  const forgotRef = useRef(null);
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box display="flex" minHeight={[0, 0, '100vh']} alignItems="center">
        <Box
          py={3}
          width="100%"
          display="flex"
          justifyContent="center"
          flexDirection={['column', 'column', 'row']}
        >
          <Box flex="1.5">
            <Box mb={[2, 2, 5]}>
              <Link to="/login">
                <img
                  alt="LISNR Logo"
                  src={LisnrLogo}
                  className={classes.logo}
                />
              </Link>
            </Box>
            <Box mb={[2, 2, 5]} pr={[0, 0, 22]}>
              <Typography variant="h1">Welcome to the LISNR Portal</Typography>
            </Box>
            <Box mb={[2, 2, 5]}>
              <Typography>
                By logging in you agree to the
                <br />
                <Link to="/eula.pdf" target="_blank" rel="noopener">
                  LISNR Inc. Trial Agreement and End User License Agreement
                </Link>
              </Typography>
              <Box my={2}>
                <Typography>
                  <Link to="//lisnr.com/privacy" target="_blank" rel="noopener">
                    Privacy Policy
                  </Link>
                </Typography>
              </Box>
            </Box>
            <Box mb={[2, 2, 15]}>
              <Button
                component={Link}
                to="//lisnr.com/contact"
                target="_blank"
                rel="noopener"
                color="primary"
                variant="contained"
              >
                Request Access
              </Button>
            </Box>
            <Box mb={[2, 2, 0]}>
              <Link
                to="//www.facebook.com/getlisnr"
                target="_blank"
                rel="noopener"
              >
                <Facebook className={classes.socialIcon} />
              </Link>
              <Link to="//instagram.com/lisnr" target="_blank" rel="noopener">
                <Instagram className={classes.socialIcon} />
              </Link>
              <Link
                to="//linkedin.com/company/lisnr"
                target="_blank"
                rel="noopener"
              >
                <LinkedIn className={classes.socialIcon} />
              </Link>
              <Link to="//twitter.com/lisnr" target="_blank" rel="noopener">
                <Twitter className={classes.socialIcon} />
              </Link>
            </Box>
          </Box>
          <Box flex="1">
            <Paper className={classes.paper}>
              <Route exact path="/login">
                {({ match }: RouteChildrenProps<any>) => (
                  <CSSTransition
                    nodeRef={loginRef}
                    in={match !== null}
                    timeout={300}
                    classNames={{
                      enter: classes.leftEnter,
                      enterActive: classes.leftEnterActive,
                      exit: classes.leftExit,
                      exitActive: classes.leftExitActive,
                    }}
                    unmountOnExit
                  >
                    <div ref={loginRef} className={classes.left}>
                      <LoginForm />
                    </div>
                  </CSSTransition>
                )}
              </Route>
              <Route exact path="/login/forgot">
                {({ match }: RouteChildrenProps<any>) => (
                  <CSSTransition
                    nodeRef={forgotRef}
                    in={match !== null}
                    timeout={300}
                    classNames={{
                      enter: classes.rightEnter,
                      enterActive: classes.rightEnterActive,
                      exit: classes.rightExit,
                      exitActive: classes.rightExitActive,
                    }}
                    unmountOnExit
                  >
                    <div ref={forgotRef} className={classes.right}>
                      <ForgotPasswordForm />
                    </div>
                  </CSSTransition>
                )}
              </Route>
              <Route exact path="/forgot-password/:token">
                {({ match }: RouteChildrenProps<any>) => (
                  <CSSTransition
                    nodeRef={forgotRef}
                    in={match !== null}
                    timeout={300}
                    classNames={{
                      enter: classes.rightEnter,
                      enterActive: classes.rightEnterActive,
                      exit: classes.rightExit,
                      exitActive: classes.rightExitActive,
                    }}
                    unmountOnExit
                  >
                    <div ref={forgotRef} className={classes.right}>
                      <ResetPasswordForm />
                    </div>
                  </CSSTransition>
                )}
              </Route>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
