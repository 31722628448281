import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import { AccountInfo } from 'Features/Account/AccountInfo';
import { selectAccount } from 'Features/Account/accountSlice';
import { ManageUsers } from 'Features/Account/ManageUsers';
import {
  selectIsAuthenticated,
  selectIsAuthorized,
} from 'Features/Auth/authSlice';
import { ChangePassword } from 'Features/Auth/ChangePassword';
import { ExpiredTrial } from 'Features/Auth/ExpiredTrial';
import { Login } from 'Features/Auth/Login';
import { Suspended } from 'Features/Auth/Suspended';
import { UserDisabled } from 'Features/Auth/UserDisabled';
import { Layout } from 'Features/Main/Layout';
import { Welcome } from 'Features/Main/Welcome';
import { ApiTokens } from 'Features/Projects/ApiTokens';
import { AppToken } from 'Features/Projects/AppToken';
import { Project } from 'Features/Projects/Project';
import { ProjectDrawer } from 'Features/Projects/ProjectDrawer';
import { ProjectLayout } from 'Features/Projects/ProjectLayout';
import { SdkTokens } from 'Features/Projects/SdkTokens';
import { SdkDownload } from 'Features/Sdk/SdkDownload';
import { EditUserProfile } from 'Features/User/EditUserProfile';
import { UserProfile } from 'Features/User/UserProfile';
import {
  selectIsAccountAdmin,
  selectPasswordChangeRequired,
  selectUserEnabled,
} from 'Features/User/userSlice';
import { productDisplayName } from 'Utils/productDisplayName';

import { AccessErrorDialog } from './AccessErrorDialog';
import { NotFound } from './NotFound';
import { RouteNotFound } from './RouteNotFound';

// Use redirection strategy described in links below to handle "not found" with
// nested routes:
// - https://github.com/ReactTraining/react-router/issues/4698#issuecomment-314419439
// - https://github.com/ReactTraining/react-router/issues/4685

const CaptureRouteNotFound: React.FC = ({ children }) => {
  const location = useLocation();
  // @ts-ignore: Property 'notFoundError' does not exist on type '{}'.
  return location?.state?.notFoundError ? <NotFound /> : <>{children}</>;
};

function PublicRoutes() {
  return (
    <Switch>
      <Route path={['/login', '/forgot-password']}>
        <Login />
      </Route>
      <Route>
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
}

function UnauthorizedRoutes() {
  const isUserEnabled = useSelector(selectUserEnabled);
  const passwordChangeRequired = useSelector(selectPasswordChangeRequired);
  const { subscriptionState } = useSelector(selectAccount);

  let main = null;
  if (isUserEnabled === false) {
    main = <UserDisabled />;
  } else if (passwordChangeRequired === true) {
    main = <ChangePassword />;
  } else if (subscriptionState === 'suspended') {
    main = <Suspended />;
  } else if (subscriptionState === 'expired_trial') {
    main = <ExpiredTrial />;
  }

  return (
    <>
      <Switch>
        <Route exact path="/">
          {main}
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>

      <AccessErrorDialog />
    </>
  );
}

function PrivateRoutes() {
  const isAccountAdmin = useSelector(selectIsAccountAdmin);

  return (
    <>
      <Switch>
        <Route exact path="/login">
          <Redirect to="/" />
        </Route>
        <Route>
          <Switch>
            <Route exact path="/">
              <Welcome />
            </Route>
            <Route exact path="/project/:projectId">
              <ProjectLayout
                getContent={(project) => ({
                  title: project.name,
                  subtitle: productDisplayName(project.sdk_type),
                  drawerContent: <ProjectDrawer project={project} />,
                  content: <Project project={project} />,
                })}
              />
            </Route>
            <Route path="/project/:projectId/api-tokens">
              <ProjectLayout
                getContent={(project) => ({
                  documentTitle: `${project.name} - API Tokens`,
                  title: 'Manage API Tokens for this project',
                  drawerContent: (
                    <ProjectDrawer
                      project={project}
                      selectedActionName="API Tokens"
                    />
                  ),
                  content: <ApiTokens project={project} />,
                })}
              />
            </Route>
            <Route path="/project/:projectId/app-token">
              <ProjectLayout
                getContent={(project) => ({
                  documentTitle: `${project.name} - App Token`,
                  title: 'Manage App Token for this project',
                  drawerContent: (
                    <ProjectDrawer
                      project={project}
                      selectedActionName="App Token"
                    />
                  ),
                  content: <AppToken project={project} />,
                })}
              />
            </Route>
            <Route path="/project/:projectId/sdk-tokens">
              <ProjectLayout
                getContent={(project) => ({
                  documentTitle: `${project.name} - SDK Tokens`,
                  title: 'Manage SDK Tokens for this project',
                  drawerContent: (
                    <ProjectDrawer
                      project={project}
                      selectedActionName="SDK Tokens"
                    />
                  ),
                  content: <SdkTokens project={project} />,
                })}
              />
            </Route>
            <Route exact path="/account">
              <Layout
                documentTitle="Account Info"
                title="Account Info"
                content={<AccountInfo />}
              />
            </Route>
            {isAccountAdmin && (
              <Route exact path="/account/users">
                <Layout
                  documentTitle="Manage Users"
                  title="Manage Users"
                  content={<ManageUsers />}
                />
              </Route>
            )}
            <Route exact path="/sdks">
              <Layout
                documentTitle="Download SDKs"
                title="Download SDKs"
                content={<SdkDownload />}
              />
            </Route>
            <Route exact path="/user-profile">
              <Layout
                documentTitle="User Profile"
                title="User Profile"
                content={<UserProfile />}
              />
            </Route>
            <Route exact path="/edit-user-profile">
              <Layout
                documentTitle="Edit User Profile"
                title="Edit User Profile"
                content={<EditUserProfile />}
              />
            </Route>
            <RouteNotFound />
          </Switch>
        </Route>
      </Switch>

      <AccessErrorDialog />
    </>
  );
}

export function Routes() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAuthorized = useSelector(selectIsAuthorized);

  let main = null;
  if (!isAuthenticated) {
    main = <PublicRoutes />;
  } else if (!isAuthorized) {
    main = <UnauthorizedRoutes />;
  } else {
    main = <PrivateRoutes />;
  }

  return (
    <Router>
      <CaptureRouteNotFound>{main}</CaptureRouteNotFound>
    </Router>
  );
}
