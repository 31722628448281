import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'notistack';

import * as api from 'Api';
import { getAllPages } from 'Api/pagination';
import { RouteNotFound } from 'App/RouteNotFound';
import { ConfirmationDialog } from 'Components/ConfirmationDialog';
import { LoadingButton } from 'Components/LoadingButton';
import { useIsMounted } from 'Hooks/useIsMounted';
import { formatLastActivity } from 'Utils/formatLastActivity';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  createButton: {
    margin: theme.spacing(1, 0),
  },
  card: {
    borderRadius: 8,
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    // row
    '& > div': {
      display: 'flex',
      minHeight: theme.spacing(3),
      marginBottom: theme.spacing(1),

      // label
      '& div': {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
        width: 150,
      },
    },
  },
  token: {
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[300],
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    fontFamily: 'Roboto Mono, mono',
    fontSize: 'smaller',
    fontWeight: 300,
    padding: theme.spacing(1),
    wordBreak: 'break-all',
  },
  progress: {
    color: '#fff8dc',
  },
}));

function formatCreatedAt({ created_at }: api.ApiToken) {
  return created_at.split('T')[0];
}

// Copy a token to the clipboard
function copyToken(token: string) {
  return navigator.clipboard.writeText(token);
}

interface ApiTokenProps {
  token: api.ApiToken;
  onDelete: () => void;
}

function ApiTokenCard({ token, onDelete }: ApiTokenProps) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div>
          <div>Created</div>
          <span>{formatCreatedAt(token)}</span>
        </div>
        <div>
          <div>Last activity</div>
          <span>{formatLastActivity(token.last_activity)}</span>
        </div>
        <div>
          <div>Token</div>
        </div>
        <div>
          <span className={classes.token}>{token.token}</span>
        </div>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          size="small"
          onClick={async () => {
            await copyToken(token.token);
            enqueueSnackbar('Token copied to clipboard');
          }}
          startIcon={<FileCopyIcon />}
        >
          Copy API Token
        </Button>
        <Button
          variant="text"
          size="small"
          color="primary"
          disableElevation
          onClick={() => onDelete()}
          startIcon={<DeleteIcon />}
        >
          Delete API Token
        </Button>
      </CardActions>
    </Card>
  );
}

export interface ApiTokensProps {
  project: api.Project;
}

export function ApiTokens({ project }: ApiTokensProps) {
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [apiTokens, setApiTokens] = useState<api.ApiToken[] | null>(null);
  const [isCreatingApiToken, setIsCreatingApiToken] = useState(false);
  const [apiTokenToDelete, setApiTokenToDelete] = useState<api.ApiToken | null>(
    null
  );
  const [isDeletingApiToken, setIsDeletingApiToken] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  // Create a new API Token
  async function createApiToken() {
    setIsCreatingApiToken(true);

    try {
      const { data: newApiToken } = await api.createApiToken(project.id);
      if (!isMounted.current) {
        return;
      }
      setApiTokens((apiTokens) => [newApiToken, ...apiTokens!]);
      enqueueSnackbar('New API Token created', { variant: 'success' });
    } catch (err) {
      if (!isMounted.current) {
        return;
      }
      enqueueSnackbar('Error creating API Token', { variant: 'error' });
    }

    setIsCreatingApiToken(false);
  }

  // Delete an API Token
  async function deleteApiToken(
    projectId: string,
    tokenToDelete: api.ApiToken
  ) {
    try {
      await api.deleteApiToken(project.id, tokenToDelete.id);
      if (!isMounted.current) {
        return;
      }

      setApiTokens((apiTokens) => {
        return apiTokens!.filter((token) => token.id !== tokenToDelete.id);
      });

      enqueueSnackbar('API Token deleted', { variant: 'success' });
    } catch (err) {
      if (!isMounted.current) {
        return;
      }
      enqueueSnackbar('Error deleting API Token', { variant: 'error' });
    }
  }

  // Delete confirmation dialog cancel handler
  function handleDialogCancel() {
    setDialogOpen(false);
    setApiTokenToDelete(null);
  }

  // Delete confirmation dialog confirm handler
  async function handleDialogConfirm() {
    setDialogOpen(false);
    setIsDeletingApiToken(true);

    if (apiTokenToDelete !== null) {
      await deleteApiToken(project.id, apiTokenToDelete);
      setApiTokenToDelete(null);
    }

    setIsDeletingApiToken(false);
  }

  // Load project and its API tokens
  // TODO: update account as well?
  useEffect(() => {
    async function fetchApiTokens() {
      let errorMessage = null;

      try {
        const apiTokens = await getAllPages(
          (request) => api.getApiTokens(project.id, request),
          {}
        );
        setApiTokens(apiTokens);
      } catch (err) {
        // Skip setting the error message if project isn't found. This ensures
        // that the next render causes a redirect to the 404 page.
        if (err.response?.status !== 404) {
          errorMessage =
            err.response?.data.message ?? err.message ?? 'Unknown error';
        }
      }

      if (isMounted.current) {
        setErrorMessage(errorMessage);
        setLoading(false);
      }
    }

    if (project.sdk_type === 'radius' || project.sdk_type === 'legacy') {
      fetchApiTokens();
    } else {
      setLoading(false);
    }
  }, [isMounted, project]);

  let content = null;

  if (loading) {
    content = (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else if (errorMessage) {
    content = <Typography color="error">Error: {errorMessage}</Typography>;
  } else if (project.sdk_type !== 'radius' && project.sdk_type !== 'legacy') {
    return <RouteNotFound />;
  } else if (apiTokens) {
    content = (
      <>
        <LoadingButton
          variant="contained"
          color="primary"
          disableElevation
          onClick={createApiToken}
          disabled={isCreatingApiToken}
          loading={isCreatingApiToken}
          className={classes.createButton}
        >
          Create API Token
        </LoadingButton>
        {apiTokens.length === 0 ? (
          <Typography variant="body1">No API Tokens available.</Typography>
        ) : (
          <>
            {apiTokens.map((token) => (
              <ApiTokenCard
                key={token.id}
                token={token}
                onDelete={() => {
                  setApiTokenToDelete(token);
                  setDialogOpen(true);
                }}
              />
            ))}
          </>
        )}
      </>
    );
  } else {
    return <RouteNotFound />;
  }

  return (
    <>
      <Container disableGutters>{content}</Container>
      <ConfirmationDialog
        open={dialogOpen}
        onCancel={handleDialogCancel}
        onConfirm={handleDialogConfirm}
        titleText="Delete API Token?"
        contentText={
          'Anything using this token will no longer authenticate to the API.'
        }
        confirmButtonText="Delete"
      ></ConfirmationDialog>
      <Backdrop className={classes.backdrop} open={isDeletingApiToken}>
        <CircularProgress className={classes.progress} />
      </Backdrop>
    </>
  );
}
