import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import faker from 'faker';

import { ProjectList } from 'Features/Projects/ProjectList';
import { ToneCalculator } from 'Features/Tones/ToneCalculator';
import { selectFirstName } from 'Features/User/userSlice';

import { Layout } from './Layout';

export const Welcome = () => {
  const firstName = useSelector(selectFirstName);

  const title = firstName !== null ? `Hello, ${firstName}.` : 'Hello.';
  const subtitle = faker.lorem.sentence();

  return (
    <Layout
      title={title}
      subtitle={subtitle}
      content={
        <>
          <ProjectList />
          <Box mt={10}>
            <ToneCalculator />
          </Box>
        </>
      }
    />
  );
};
