import { ApiErrorCallback } from 'Api/errors';

import { getErrorAction } from './getErrorAction';
import { store } from './store';

// API error callback to dispatch Redux actions in response to API call errors.
export const apiErrorCallback: ApiErrorCallback = (code, detail) => {
  const action = getErrorAction(code, detail);
  if (action === null) {
    return;
  }

  // Work around TypeScript "No overload matches this call" error by narrowing
  // the action's type.
  if (typeof action === 'function') {
    store.dispatch(action);
  } else {
    store.dispatch(action);
  }
};
