import { Action } from '@reduxjs/toolkit';

import { ErrorCode, ErrorDetail } from 'Api/errors';

import {
  accountSuspended,
  passwordChangeRequired,
  userDisabled,
} from './errorActions';
import {
  forbiddenErrorIfAuthorized,
  unauthorizedErrorIfAuthorized,
} from './errorThunks';
import { AppThunk } from './store';

// Map API call errors to error actions.
export const getErrorAction = (
  code: ErrorCode,
  detail?: ErrorDetail
): Action | AppThunk | null => {
  let action = null;

  switch (code) {
    case 'unauthorized':
      action = unauthorizedErrorIfAuthorized();
      break;

    case 'forbidden':
      switch (detail) {
        case 'userDisabled':
          action = userDisabled();
          break;
        case 'passwordChangeRequired':
          action = passwordChangeRequired();
          break;
        case 'accountSuspended':
          action = accountSuspended();
          break;
        case 'other':
          action = forbiddenErrorIfAuthorized();
          break;
        default:
          break;
      }
      break;

    default:
      break;
  }

  return action;
};
