import { AxiosRequestConfig } from 'axios';

import { axiosInstance as instance } from './axiosInstance';
import { User } from './dataTypes';
import { PaginationParams } from './pagination';
import { SortParams } from './sort';

export type GetUserResponse = User;

export interface GetUsersParams {
  search?: string;
}

export type GetUsersRequest = GetUsersParams & PaginationParams & SortParams;

export type GetUsersResponse = User[];

export interface UpdateUserRequest {
  username: string;
  first_name: string;
  last_name: string;
  email: string | null;
}

export type UpdateUserResponse = GetUserResponse;

export function getUser() {
  return instance.get<GetUserResponse>('/users/me');
}

export function getUsers(
  request: GetUsersRequest,
  config?: AxiosRequestConfig
) {
  return instance.get<GetUsersResponse>('/users', {
    ...config,
    params: request,
  });
}

export function updateUser(request: UpdateUserRequest) {
  return instance.patch<UpdateUserResponse>('/users/me', request);
}
