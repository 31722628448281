import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';

import { AppDispatch } from 'App/store';
import { LoadingButton } from 'Components/LoadingButton';
import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectUsername,
  updateUser,
} from 'Features/User/userSlice';
import { useIsMounted } from 'Hooks/useIsMounted';

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    marginRight: theme.spacing(1),
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

interface Inputs {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
}

export function EditUserProfile() {
  const dispatch: AppDispatch = useDispatch();
  const { control, errors, handleSubmit, setError } = useForm<Inputs>();
  const history = useHistory();
  const isMounted = useIsMounted();
  const email = useSelector(selectEmail);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const username = useSelector(selectUsername);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const classes = useStyles();

  async function onSubmit(inputs: Inputs) {
    setErrorMessage(null);
    setIsSubmitting(true);

    const result = await dispatch(updateUser(inputs));

    if (updateUser.fulfilled.match(result)) {
      setDialogOpen(true);
    } else {
      let errorDetail = null;
      if (result.payload) {
        const { field, message } = result.payload;

        // Visually indicate which field has an error.
        // Show a generic error if the specific field isn't provided.
        if (field) {
          setError(field, { message: '' });

          // Override the backend error message for certain fields
          if (field === 'firstName') {
            errorDetail = 'First name is invalid.';
          } else if (field === 'lastName') {
            errorDetail = 'Last name is invalid.';
          } else {
            errorDetail = message;
          }
        }
      } else {
        errorDetail = result.error.message ?? 'Unknown error.';
      }

      // Construct error message with error details
      let errorMessage = 'Unable to update your profile.';
      if (errorDetail) {
        if (!errorDetail.endsWith('.')) {
          errorDetail += '.';
        }
        errorMessage += ' ' + errorDetail;
      }

      if (isMounted.current) {
        setErrorMessage(errorMessage);
      }
    }

    if (isMounted.current) {
      setIsSubmitting(false);
    }
  }

  function handleClose() {
    setDialogOpen(false);
    history.push('/user-profile');
  }

  return (
    <>
      <Container maxWidth="md">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <Typography
                variant="h5"
                component="h2"
                color="textSecondary"
                gutterBottom
              >
                Edit User Profile
              </Typography>
              <Box>
                <Controller
                  as={TextField}
                  name="firstName"
                  control={control}
                  defaultValue={firstName}
                  rules={{ required: 'Required' }}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                  disabled={isSubmitting}
                  fullWidth
                  required
                  variant="outlined"
                  margin="normal"
                  id="firstname"
                  label="First Name"
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Box>
              <Box>
                <Controller
                  as={TextField}
                  name="lastName"
                  control={control}
                  defaultValue={lastName}
                  rules={{ required: 'Required' }}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName?.message}
                  disabled={isSubmitting}
                  fullWidth
                  required
                  variant="outlined"
                  margin="normal"
                  id="lastname"
                  label="Last Name"
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Box>
              <Box>
                <Controller
                  as={TextField}
                  name="username"
                  control={control}
                  defaultValue={username}
                  rules={{ required: 'Required' }}
                  error={Boolean(errors.username)}
                  helperText={errors.username?.message}
                  disabled={isSubmitting}
                  fullWidth
                  required
                  variant="outlined"
                  margin="normal"
                  id="username"
                  label="Username"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Box>
              <Box>
                <Controller
                  as={TextField}
                  name="email"
                  control={control}
                  defaultValue={email}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  disabled={isSubmitting}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  id="email"
                  label="Email"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Box>
              {errorMessage && (
                <Typography color="error" className={classes.errorMessage}>
                  <ErrorIcon className={classes.errorIcon} />
                  Error: {errorMessage}
                </Typography>
              )}
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                component={Link}
                to="/user-profile"
                color="primary"
                size="small"
                disableElevation
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </CardActions>
          </Card>
        </form>
      </Container>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your user profile has been updated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
