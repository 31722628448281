import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'App/store';
import { getAccountInfo } from 'Features/Account/accountSlice';
import { selectIsInitialized, setIsInitialized } from 'Features/App/appSlice';
import {
  initTokenFromLocalStorage,
  selectIsAuthenticated,
} from 'Features/Auth/authSlice';
import { selectLocalToken } from 'Features/Auth/localAuthSlice';
import { getUserInfo } from 'Features/User/userSlice';

interface SessionInitializerProps {
  loadingIndicator?: React.ReactNode;
}

export const SessionInitializer: React.FC<SessionInitializerProps> = ({
  children,
  loadingIndicator = null,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const localToken = useSelector(selectLocalToken);
  const isInitialized = useSelector(selectIsInitialized);
  const [ready, setReady] = useState(false);

  // Initialize token from local storage.
  // Run only once per session.
  useEffect(() => {
    if (isInitialized) {
      return;
    }

    if (localToken !== null) {
      dispatch(initTokenFromLocalStorage(localToken));
    }

    dispatch(setIsInitialized());
  }, [dispatch, isInitialized, localToken]);

  // Fetch user and account information once authenticated.
  // Show loading indicator while waiting.
  useEffect(() => {
    async function fetchUserAndAccountInfo() {
      setReady(false);
      // Note: The getUserInfo() and getAccountInfo() thunks will always return
      // a resolved promise.
      await Promise.all([dispatch(getUserInfo()), dispatch(getAccountInfo())]);
      setReady(true);
    }

    if (!isInitialized) {
      return;
    }

    if (isAuthenticated) {
      fetchUserAndAccountInfo();
    } else {
      setReady(true);
    }
  }, [dispatch, isInitialized, isAuthenticated]);

  const loading = !isInitialized || (isAuthenticated && !ready);
  return <>{loading ? loadingIndicator : children}</>;
};
