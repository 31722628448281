import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { setApiErrorCallback } from 'Api/errors';
import { apiErrorCallback } from 'App/apiErrorCallback';
import App from 'App/App';
import { persistor, store } from 'App/store';

import '@fontsource/roboto-mono/300.css';
import '@fontsource/roboto-mono/400.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/300-italic.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/500-italic.css';
import '@fontsource/roboto/700-italic.css';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';
import '@fontsource/open-sans/300-italic.css';
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/600-italic.css';
import '@fontsource/open-sans/700-italic.css';
import '@fontsource/open-sans/800-italic.css';
import './index.css';

// Set callback to dispatch Redux actions in response to API call errors.
setApiErrorCallback(apiErrorCallback);

// Wrap the App component with Redux provider. From
// https://react-redux.js.org/api/provider:
//     Most applications will render a <Provider> at the top level, with the
//     entire app’s component tree inside of it.

// Additionally, wrap the App component with redux-persist's PersistGate to
// delay rendering until the Redux state is loaded.
// See https://github.com/rt2zz/redux-persist

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <App />
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
