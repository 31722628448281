import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import FreeBreakfast from '@material-ui/icons/FreeBreakfast';
import GetAppIcon from '@material-ui/icons/GetApp';
import LaptopMac from '@material-ui/icons/LaptopMac';
import LaptopWindows from '@material-ui/icons/LaptopWindows';
import { forEach, groupBy } from 'lodash';

import * as api from 'Api';
import { getAllPages } from 'Api/pagination';
import { useIsMounted } from 'Hooks/useIsMounted';
import { productDisplayName } from 'Utils/productDisplayName';

type ArchitectureSdkMap = Map<string, api.Sdk>;
type ProductSdkMap = Map<string, ArchitectureSdkMap>;
type PlatformSdkMap = Map<string, ProductSdkMap>;

const useStyles = makeStyles((theme) => ({
  platformGroup: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  sdkGroup: {
    display: 'flex',
  },
  sdkItem: {
    marginRight: theme.spacing(2),
  },
  sdkItemActionArea: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  sdkItemDetails: {
    display: 'flex',
    alignItems: 'center',
  },
}));

// Ordered list of platforms
const platforms: api.Platform[] = [
  'android',
  'ios',
  'windows',
  'linux',
  'macos',
];

// Ordered list of products
const products: api.Product[] = ['radius', 'point', 'legacy'];

function getIcon(platform: api.Platform) {
  switch (platform) {
    case 'android':
      return <AndroidIcon />;
    case 'ios':
      return <AppleIcon />;
    case 'windows':
      return <LaptopWindows />;
    case 'linux':
      return <FreeBreakfast />;
    case 'macos':
      return <LaptopMac />;
    default:
      return null;
  }
}

function platformDisplayName(platform: api.Platform) {
  switch (platform) {
    case 'android':
      return 'Android';
    case 'ios':
      return 'iOS';
    case 'windows':
      return 'Windows';
    case 'linux':
      return 'Linux';
    case 'macos':
      return 'macOS';
    default:
      return 'Unknown';
  }
}

interface SDKItemProps {
  sdk?: api.Sdk;
}

// SDK for a specific platform, product, and architecture
function SdkItem({ sdk }: SDKItemProps) {
  const classes = useStyles();

  if (!sdk) {
    return null;
  }

  return (
    <Card raised square className={classes.sdkItem}>
      <CardActionArea
        className={classes.sdkItemActionArea}
        component={Link}
        href={sdk.sdk}
        download
      >
        <CardContent className={classes.sdkItemDetails}>
          <Box margin={1}>
            <GetAppIcon color="action" fontSize="large" />
          </Box>
          <Box margin={1}>
            <Typography variant="body1" color="textPrimary">
              Version {sdk.version}
            </Typography>
            {sdk.architecture.length > 0 ? (
              <Typography variant="body2" color="textPrimary">
                {sdk.architecture}
              </Typography>
            ) : null}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

interface ProductGroupProps {
  product: api.Product;
  sdksByArchitecture?: ArchitectureSdkMap;
}

// SDKs for a specific platform and product
function ProductGroup({ product, sdksByArchitecture }: ProductGroupProps) {
  const classes = useStyles();

  if (!sdksByArchitecture) {
    return null;
  }

  const architectures = Array.from(sdksByArchitecture.keys()).sort();
  const title = `${productDisplayName(product)} SDK`;

  return (
    <Card variant="outlined" square>
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'h6',
          component: 'h5',
          color: 'textSecondary',
        }}
      />
      <CardContent className={classes.sdkGroup}>
        {architectures.map((architecture) => {
          return (
            <SdkItem
              key={architecture}
              sdk={sdksByArchitecture.get(architecture)}
            />
          );
        })}
      </CardContent>
    </Card>
  );
}

interface PlatformGroupProps {
  platform: api.Platform;
  sdksByProduct?: ProductSdkMap;
}

// SDKs for a specific platform
function PlatformGroup({ platform, sdksByProduct }: PlatformGroupProps) {
  const classes = useStyles();

  if (!sdksByProduct) {
    return null;
  }

  return (
    <Card className={classes.platformGroup}>
      <CardHeader
        avatar={<Avatar>{getIcon(platform)}</Avatar>}
        title={platformDisplayName(platform)}
        titleTypographyProps={{ color: 'textPrimary', variant: 'h4' }}
      />
      <CardContent>
        {products.map((product) => (
          <ProductGroup
            key={product}
            product={product}
            sdksByArchitecture={sdksByProduct.get(product)}
          />
        ))}
      </CardContent>
    </Card>
  );
}

export function SdkDownload() {
  const isMounted = useIsMounted();
  const [sdks, setSdks] = useState<PlatformSdkMap | null>(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Load SDKs
  useEffect(() => {
    async function fetchSdks() {
      let errorMessage = null;

      try {
        const data = await getAllPages(api.getSdks, {});

        //
        // Group SDKs by platform, product, and architecture
        //

        const sdkData: PlatformSdkMap = new Map();

        const groupedData = groupBy(data, 'platform');

        forEach(groupedData, (v, platform) => {
          const groupedByProduct = groupBy(v, 'sdk_type');

          forEach(groupedByProduct, (v, product) => {
            const groupedByArchitecture = groupBy(v, 'architecture');

            forEach(groupedByArchitecture, (v, architecture) => {
              // Choose most recent version for this architecture
              const sortedSdks = v.sort((a, b) => {
                return b.version.localeCompare(a.version, undefined, {
                  numeric: true,
                });
              });
              const latestSdk = sortedSdks[0];

              // Add SDK to data structure
              if (!sdkData.has(platform)) {
                sdkData.set(platform, new Map());
              }
              if (!sdkData.get(platform)?.has(product)) {
                sdkData.get(platform)?.set(product, new Map());
              }
              sdkData.get(platform)?.get(product)?.set(architecture, latestSdk);
            });
          });
        });

        setSdks(sdkData);
      } catch (err) {
        errorMessage = err.message ?? 'Unknown error';
      }
      if (isMounted.current) {
        setErrorMessage(errorMessage);
        setLoading(false);
      }
    }

    fetchSdks();
  }, [isMounted]);

  let content = null;

  if (loading) {
    content = (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else if (errorMessage) {
    content = <Typography color="error">Error: {errorMessage}</Typography>;
  } else {
    content = platforms.map((platform) => (
      <PlatformGroup
        key={platform}
        platform={platform}
        sdksByProduct={sdks?.get(platform)}
      />
    ));
  }

  return <Container maxWidth="md">{content}</Container>;
}
