import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from '@material-ui/core';

import { UnauthorizedPage } from './UnauthorizedPage';

function SuspendedMessage() {
  return (
    <>
      Your LISNR Portal account has been suspended. Contact{' '}
      <Link href="mailto:techsupport@lisnr.com">techsupport@lisnr.com</Link> for
      more information.
    </>
  );
}

// Page shown at login when the user's account is suspended.
export function Suspended() {
  const title = 'Account Suspended';

  return (
    <>
      <Helmet title={title} />
      <UnauthorizedPage title={title} content={<SuspendedMessage />} />
    </>
  );
}
