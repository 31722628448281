import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'App/rootReducer';

import { login } from './authSlice';
import { loggedOut } from './logout';

export interface LocalAuthState {
  token: string | null;
}

const initialState: LocalAuthState = {
  token: null,
};

export const localAuthSlice = createSlice({
  name: 'localAuth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Login
    builder.addCase(login.fulfilled, (state, { payload, meta: { arg } }) => {
      // Save token in local storage if "stay logged in" is enabled
      if (arg.stayLoggedIn) {
        state.token = payload.token;
      }
    });

    // Logged out
    builder.addCase(loggedOut, () => {
      return initialState;
    });
  },
});

// Selectors
export const selectLocalToken = (state: RootState) => state.localAuth.token;

export default localAuthSlice.reducer;
