import { useEffect, useRef } from 'react';

// Track whether component is mounted to avoid updating state when unmounted.
// See https://www.debuggr.io/react-update-unmounted-component/
export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}
