import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  CircularProgress,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import { AppDispatch } from 'App/store';
import { selectDrawerState, setDrawerOpen } from 'Features/Main/drawerSlice';

import { Footer } from './Footer';
import { MenuAppBar } from './MenuAppBar';

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      backgroundColor: theme.palette.darkBlue.main,
      color: theme.palette.common.white,
      width: drawerWidth,
    },
    heading: {
      background:
        'linear-gradient(to bottom right, ' +
        theme.palette.primary.main +
        ', ' +
        theme.palette.lightGreen.main +
        ')',
      color: theme.palette.common.white,
    },
  })
);

export interface LayoutProps {
  loading?: boolean;
  documentTitle?: string;
  title?: string;
  subtitle?: string;
  drawerContent?: React.ReactNode;
  content?: React.ReactNode;
}

// Layout for authenticated and authorized users.
// Based on https://material-ui.com/components/drawers/#clipped-under-the-app-bar
export const Layout = (props: LayoutProps) => {
  const {
    loading,
    documentTitle,
    title,
    subtitle,
    drawerContent,
    content,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isDrawerOpen = useSelector(selectDrawerState);

  const openDrawer = () => {
    dispatch(setDrawerOpen(true));
  };

  const closeDrawer = () => {
    dispatch(setDrawerOpen(false));
  };

  let main = null;

  if (loading) {
    main = (
      <Box display="flex" flexGrow="1" flexDirection="column">
        <Toolbar />
        <Box margin={3} textAlign="center">
          <CircularProgress />
        </Box>
      </Box>
    );
  } else {
    main = (
      <>
        {!loading && drawerContent && (
          <SwipeableDrawer
            className={classes.drawer}
            variant={isSmallScreen ? 'temporary' : 'permanent'}
            open={isDrawerOpen}
            onOpen={openDrawer}
            onClose={closeDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {isSmallScreen ? null : <Toolbar />}
            {drawerContent}
          </SwipeableDrawer>
        )}
        <Box display="flex" flexGrow="1" flexDirection="column" width="100%">
          <Toolbar />
          <Box
            alignItems="center"
            display="flex"
            minHeight={['200px', '250px', '300px']}
            justifyContent="center"
            flexDirection="column"
            textAlign="center"
            className={classes.heading}
          >
            <Typography variant="h1" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5">{subtitle}</Typography>
          </Box>

          <Box margin={[2, 4, 4]} flex="1 1 auto">
            {content}
          </Box>

          <Footer />
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet title={documentTitle} />
      <Box display="flex" height="100%">
        <MenuAppBar
          color="inherit"
          position="fixed"
          className={classes.appBar}
          drawer={drawerContent ? true : false}
        />
        {main}
      </Box>
    </>
  );
};
