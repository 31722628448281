import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  selectEmail,
  selectFullName,
  selectUsername,
} from 'Features/User/userSlice';

import { ChangePasswordForm } from './ChangePasswordForm';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(4),
  },
  table: {
    '& th': {
      width: '180px',
    },
  },
}));

export function UserProfile() {
  const email = useSelector(selectEmail);
  const fullName = useSelector(selectFullName);
  const username = useSelector(selectUsername);
  const classes = useStyles();

  const rows = [
    { name: 'Full Name', value: fullName },
    { name: 'Username', value: username },
    { name: 'Email', value: email },
  ];

  return (
    <Container maxWidth="md">
      <Card className={classes.card}>
        <CardContent>
          <Typography
            variant="h5"
            component="h2"
            color="textSecondary"
            gutterBottom
          >
            User Profile
          </Typography>
          <TableContainer>
            <Table className={classes.table}>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      <Typography variant="button" color="textSecondary" noWrap>
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.value ?? 'None'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Button
            component={Link}
            to="/edit-user-profile"
            color="primary"
            size="small"
          >
            Edit User Profile
          </Button>
        </CardActions>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            variant="h5"
            component="h2"
            color="textSecondary"
            gutterBottom
          >
            Change Password
          </Typography>
          <Container maxWidth="xs">
            <ChangePasswordForm />
          </Container>
        </CardContent>
      </Card>
    </Container>
  );
}
