import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'notistack';

import * as api from 'Api';
import { RouteNotFound } from 'App/RouteNotFound';
import { useIsMounted } from 'Hooks/useIsMounted';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    marginBottom: theme.spacing(2),
  },
  token: {
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[300],
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    fontFamily: 'Roboto Mono, mono',
    fontSize: 'smaller',
    fontWeight: 300,
    padding: theme.spacing(1),
    wordBreak: 'break-all',
  },
}));

// Copy a token to the clipboard
function copyToken(token: string) {
  return navigator.clipboard.writeText(token);
}

interface AppTokenCardProps {
  token: api.AppToken;
}

function AppTokenCard({ token }: AppTokenCardProps) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.token}>{token.token}</div>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          size="small"
          onClick={async () => {
            await copyToken(token.token);
            enqueueSnackbar('Token copied to clipboard');
          }}
          startIcon={<FileCopyIcon />}
        >
          Copy App Token
        </Button>
      </CardActions>
    </Card>
  );
}

export interface AppTokenProps {
  project: api.Project;
}

export function AppToken({ project }: AppTokenProps) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [appToken, setAppToken] = useState<api.AppToken | null>(null);

  // Load project and its App Token
  useEffect(() => {
    async function fetchAppToken() {
      let errorMessage = null;

      try {
        const results = await api.getAppToken(project.id);
        const appToken = results.data[0] ?? null;
        setAppToken(appToken);
      } catch (err) {
        // Skip setting the error message if project isn't found. This ensures
        // that the next render causes a redirect to the 404 page.
        if (err.response?.status !== 404) {
          errorMessage =
            err.response?.data.message ?? err.message ?? 'Unknown error';
        }
      }

      if (isMounted.current) {
        setErrorMessage(errorMessage);
        setLoading(false);
      }
    }

    if (project.sdk_type === 'legacy') {
      fetchAppToken();
    } else {
      setLoading(false);
    }
  }, [isMounted, project]);

  let content = null;

  if (loading) {
    content = (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else if (errorMessage) {
    content = <Typography color="error">Error: {errorMessage}</Typography>;
  } else if (project.sdk_type !== 'legacy') {
    return <RouteNotFound />;
  } else if (appToken) {
    content = <AppTokenCard token={appToken} />;
  } else {
    return <RouteNotFound />;
  }

  return <Container disableGutters>{content}</Container>;
}
