import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
    },
    link: {
      color: theme.palette.grey[800],
      margin: '0 15px',
    },
  })
);

export const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography>
        <a
          className={classes.link}
          href="mailto:techsupport@lisnr.com"
          target="_blank"
          rel="noreferrer"
        >
          Support
        </a>
      </Typography>
      <Typography>
        <Link
          className={classes.link}
          to="//lisnr.com/privacy"
          target="_blank"
          rel="noopener"
        >
          Privacy Policy
        </Link>
      </Typography>
      <Typography>
        <Link
          className={classes.link}
          to="/eula.pdf"
          target="_blank"
          rel="noopener"
        >
          EULA
        </Link>
      </Typography>
    </Box>
  );
};
