import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from '@material-ui/core';

import { UnauthorizedPage } from './UnauthorizedPage';

function ExpiredTrialMessage() {
  return (
    <>
      Thanks for using the LISNR Portal. Unfortunately, your free trial has
      expired. Contact{' '}
      <Link href="mailto:techsupport@lisnr.com">techsupport@lisnr.com</Link> to
      restore access.
    </>
  );
}

// Page shown at login when the user's trial account is expired.
export function ExpiredTrial() {
  const title = 'Expired Trial';

  return (
    <>
      <Helmet title={title} />
      <UnauthorizedPage title={title} content={<ExpiredTrialMessage />} />
    </>
  );
}
