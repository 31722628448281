import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import { AppDispatch } from 'App/store';
import { selectAccessError } from 'Features/App/appSlice';
import { logout } from 'Features/Auth/logout';

// Dialog displayed when an unhandled access error occurs. Logs out when closed.
export function AccessErrorDialog() {
  const dispatch: AppDispatch = useDispatch();
  const accessError = useSelector(selectAccessError);

  function handleClose() {
    dispatch(logout());
  }

  return (
    <Dialog
      open={accessError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Error: You are not authorized and will be logged out.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
