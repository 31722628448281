import { useEffect, useState } from 'react';

import * as api from 'Api';
import { useIsMounted } from 'Hooks/useIsMounted';

// Hook to fetch a project.
export function useFetchProject(projectId: string) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [project, setProject] = useState<api.Project | null>(null);

  useEffect(() => {
    async function fetchProject(projectId: string) {
      let errorMessage = null;

      try {
        const project = await api.getProject(projectId);
        if (isMounted.current) {
          setProject(project.data);
        }
      } catch (err) {
        // Skip setting the error message if project isn't found. This allows
        // redirecting to a 404 page.
        if (err.response?.status !== 404) {
          errorMessage =
            err.response?.data.message ?? err.message ?? 'Unknown error';
        }
      }

      if (isMounted.current) {
        setErrorMessage(errorMessage);
        setLoading(false);
      }
    }

    fetchProject(projectId);

    return () => {};
  }, [isMounted, projectId]);

  return {
    loading,
    errorMessage,
    project,
  };
}
