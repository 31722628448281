import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import * as api from 'Api';
import { getAllPages } from 'Api/pagination';
import { RouteNotFound } from 'App/RouteNotFound';
import { useIsMounted } from 'Hooks/useIsMounted';
import { formatLastActivity } from 'Utils/formatLastActivity';
import { productDisplayName } from 'Utils/productDisplayName';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    // row
    '& > div': {
      display: 'flex',
      minHeight: theme.spacing(3),
      marginBottom: theme.spacing(1),

      // label
      '& div': {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
        width: 200,
      },
    },
  },
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  token: {
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[300],
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    fontFamily: 'Roboto Mono, mono',
    fontSize: 'smaller',
    fontWeight: 300,
    padding: theme.spacing(1),
    wordBreak: 'break-all',
  },
}));

function formatExpiration({ exp }: api.SdkToken) {
  return exp.split('T')[0];
}

function formatProduct({ version, sdk_type }: api.SdkToken) {
  let product = productDisplayName(sdk_type);
  if (sdk_type === 'point') {
    product += version === 4 ? ' 1.2 and earlier' : ' 1.3';
  }
  return product;
}

// Copy a token to the clipboard
function copyToken(token: string) {
  return navigator.clipboard.writeText(token);
}

interface SdkTokenProps {
  token: api.SdkToken;
}

function SdkTokenCard({ token }: SdkTokenProps) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div>
          <div>Expires</div>
          <span>{formatExpiration(token)}</span>
        </div>
        <div>
          <div>Last activity</div>
          <span>{formatLastActivity(token.last_activity)}</span>
        </div>
        <div>
          <div>Product type</div>
          <span>{formatProduct(token)}</span>
        </div>
        {token.version === 4 && (
          <>
            <div>
              <div>Apple bundle ID</div>
              <span>
                {token.apple_bundle_id.length === 0
                  ? 'None'
                  : token.apple_bundle_id}
              </span>
            </div>
            <div>
              <div>Release Android key hash</div>
              <span>
                {token.android_key_hash.release.length === 0
                  ? 'None'
                  : token.android_key_hash.release}
              </span>
            </div>
            <div>
              <div>Debug Android key hashes</div>
              <span>
                {token.android_key_hash.debug.length === 0 ? (
                  'None'
                ) : (
                  <ul className={classes.list}>
                    {token.android_key_hash.debug.map((id) => (
                      <li key={id}>{id}</li>
                    ))}
                  </ul>
                )}
              </span>
            </div>
          </>
        )}
        {token.version === 5 && (
          <>
            <div>
              <div>Apple bundle IDs</div>
              <span>
                {token.apple_bundle_ids.length === 0 ? (
                  'None'
                ) : (
                  <ul className={classes.list}>
                    {token.apple_bundle_ids.map((id) => (
                      <li key={id}>{id}</li>
                    ))}
                  </ul>
                )}
              </span>
            </div>
            <div>
              <div>Android application IDs</div>
              <span>
                {token.android_application_ids.length === 0 ? (
                  'None'
                ) : (
                  <ul className={classes.list}>
                    {token.android_application_ids.map((id) => (
                      <li key={id}>{id}</li>
                    ))}
                  </ul>
                )}
              </span>
            </div>
          </>
        )}
        <div>
          <div>Token</div>
        </div>
        <div>
          <span className={classes.token}>{token.token}</span>
        </div>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          size="small"
          onClick={async () => {
            await copyToken(token.token);
            enqueueSnackbar('Token copied to clipboard');
          }}
        >
          Copy SDK Token
        </Button>
      </CardActions>
    </Card>
  );
}

export interface SdkTokensProps {
  project: api.Project;
}

export function SdkTokens({ project }: SdkTokensProps) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [sdkTokens, setSdkTokens] = useState<api.SdkToken[] | null>(null);

  // Load project and its SDK tokens
  // TODO: update account as well?
  useEffect(() => {
    async function fetchSdkTokens() {
      let errorMessage = null;

      try {
        const sdkTokens = await getAllPages(
          (request) => api.getSdkTokens(project.id, request),
          {}
        );
        setSdkTokens(sdkTokens);
      } catch (err) {
        // Skip setting the error message if project isn't found. This ensures
        // that the next render causes a redirect to the 404 page.
        if (err.response?.status !== 404) {
          errorMessage =
            err.response?.data.message ?? err.message ?? 'Unknown error';
        }
      }

      if (isMounted.current) {
        setErrorMessage(errorMessage);
        setLoading(false);
      }
    }

    if (project.sdk_type === 'point' || project.sdk_type === 'radius') {
      fetchSdkTokens();
    } else {
      setLoading(false);
    }
  }, [isMounted, project]);

  let content = null;

  if (loading) {
    content = (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else if (errorMessage) {
    content = <Typography color="error">Error: {errorMessage}</Typography>;
  } else if (project.sdk_type !== 'point' && project.sdk_type !== 'radius') {
    return <RouteNotFound />;
  } else if (sdkTokens) {
    content = (
      <>
        {sdkTokens.length === 0 ? (
          <Typography variant="body1">No SDK Tokens available.</Typography>
        ) : (
          <>
            {sdkTokens.map((token) => (
              <SdkTokenCard key={token.id} token={token} />
            ))}
          </>
        )}
      </>
    );
  } else {
    return <RouteNotFound />;
  }

  return <Container disableGutters>{content}</Container>;
}
