import React from 'react';
import { Helmet } from 'react-helmet-async';

import { UnauthorizedPage } from './UnauthorizedPage';

function UserDisabledMessage() {
  return (
    <>
      Thanks for using the LISNR Portal. Your user has been disabled by the
      account administrator.
    </>
  );
}

// Page shown at login when the account admin disabled the user.
export function UserDisabled() {
  const title = 'User Disabled';

  return (
    <>
      <Helmet title={title} />
      <UnauthorizedPage title={title} content={<UserDisabledMessage />} />
    </>
  );
}
