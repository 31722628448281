import { axiosInstance as instance } from './axiosInstance';

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  is_password_change_required: boolean;
}

export interface LogoutResponse {
  message: string;
}

export interface ChangePasswordRequest {
  current_password: string;
  new_password: string;
}

export interface ChangePasswordResponse {
  message: string;
}

export interface RequestPasswordResetRequest {
  username: string;
}

export interface RequestPasswordResetResponse {
  message: string;
}

export interface ResetPasswordRequest {
  token: string;
  password: string;
}

export interface ResetPasswordResponse {
  message: string;
}

export function login(request: LoginRequest) {
  return instance.post<LoginResponse>('/auth/login/', request);
}

export function logout() {
  return instance.post<LogoutResponse>('/auth/logout/');
}

export function changePassword(request: ChangePasswordRequest) {
  return instance.post<ChangePasswordResponse>(
    '/auth/password/change',
    request
  );
}

export function requestPasswordReset(request: RequestPasswordResetRequest) {
  return instance.post<RequestPasswordResetResponse>(
    '/auth/password/reset/',
    request
  );
}

export function resetPassword({ token, password }: ResetPasswordRequest) {
  return instance.post<ResetPasswordResponse>(`/auth/password/reset/${token}`, {
    password,
  });
}
