import { Dispatch, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';

import { setAuthorizationToken } from 'Api';

import { initTokenFromLocalStorage, login } from './authSlice';
import { loggedOut } from './logout';

/**
 * Middleware to set the authorization token in API requests.
 */
export const authorizationMiddleware: Middleware = (api: MiddlewareAPI) => (
  next: Dispatch
) => (action) => {
  if (initTokenFromLocalStorage.match(action)) {
    setAuthorizationToken(action.payload);
  } else if (login.fulfilled.match(action)) {
    setAuthorizationToken(action.payload.token);
  } else if (loggedOut.match(action)) {
    setAuthorizationToken(null);
  } else if (action.type === REHYDRATE && action.key === 'auth') {
    if (action.payload) {
      setAuthorizationToken(action.payload.token);
    }
  }

  const res = next(action);

  return res;
};
