import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';

import * as api from 'Api';
import { LoadingButton } from 'Components/LoadingButton';
import { PasswordTextField } from 'Components/PasswordTextField';
import { useIsMounted } from 'Hooks/useIsMounted';
import { validatePassword } from 'Utils/validatePassword';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
  },
  helpIcon: {
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
  errorIcon: {
    marginRight: theme.spacing(1),
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Inputs {
  currentPassword: string;
  newPassword: string;
}

// TODO: Pending design, refactor with ChangePassword
export function ChangePasswordForm() {
  const { control, errors, handleSubmit, reset, setError } = useForm<Inputs>();
  const isMounted = useIsMounted();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const classes = useStyles();

  async function onSubmit({ currentPassword, newPassword }: Inputs) {
    setErrorMessage(null);
    setIsSubmitting(true);

    try {
      const { data } = await api.changePassword({
        current_password: currentPassword,
        new_password: newPassword,
      });
      setSuccessMessage(data.message);
      setDialogOpen(true);
    } catch (error) {
      let errorDetail = null;

      if (error.response) {
        // Parse the errored field from message to indicate visually which
        // field has an error. Show the server-side error message only for the
        // new password field. Show a generic error if the specific field
        // isn't provided.
        const { code, message } = error.response.data;
        if (code === 'invalid') {
          // The error message may have form: "<field_name>: <message>".
          // 'current_password' errors if the password is incorrect.
          // 'new_password' errors if it fails server-side validation.
          const [fieldName, fieldError] = message
            .split(':')
            .map((str: string) => str.trim());
          if (fieldName === 'current_password') {
            setError('currentPassword', { message: '' });
            errorDetail = 'Current password is incorrect.';
          } else if (fieldName === 'new_password') {
            setError('newPassword', { message: '' });
            errorDetail = fieldError;
          }
        }
      } else {
        errorDetail = error.message ?? 'Unknown error.';
      }

      if (!isMounted.current) {
        return;
      }

      // Construct error message with error details.
      let errorMessage = 'Unable to change your password.';
      if (errorDetail) {
        if (!errorDetail.endsWith('.')) {
          errorDetail += '.';
        }
        errorMessage += ' ' + errorDetail;
      }

      setErrorMessage(errorMessage);
    }

    setIsSubmitting(false);
  }

  function handleClose() {
    setDialogOpen(false);
    reset();
  }

  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <Grid container>
          <Grid item xs={10}>
            <Controller
              as={PasswordTextField}
              name="currentPassword"
              control={control}
              defaultValue=""
              rules={{
                required: 'Required',
              }}
              error={Boolean(errors.currentPassword)}
              helperText={errors.currentPassword?.message}
              disabled={isSubmitting}
              fullWidth
              required
              variant="outlined"
              margin="normal"
              id="currentPassword"
              label="Current password"
              autoComplete="current-password"
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Controller
              as={PasswordTextField}
              name="newPassword"
              control={control}
              defaultValue=""
              rules={{
                required: 'Required',
                validate: (password) => {
                  let message;
                  try {
                    validatePassword(password);
                  } catch (error) {
                    message = error.message;
                  }
                  return message ?? true;
                },
              }}
              error={Boolean(errors.newPassword)}
              helperText={errors.newPassword?.message}
              disabled={isSubmitting}
              fullWidth
              required
              variant="outlined"
              margin="normal"
              id="newPassword"
              label="New password"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title="6 to 25 characters, at least one number and one special character (!@#$%^&*_)"
              placement="right"
              arrow
            >
              <Typography color="textSecondary">
                <HelpIcon className={classes.helpIcon} />
              </Typography>
            </Tooltip>
          </Grid>
          <Grid container>
            <Grid item xs={10}>
              <Box margin="normal">
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  disableElevation
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  className={classes.submit}
                >
                  Change Password
                </LoadingButton>
              </Box>
              {errorMessage && (
                <Typography color="error" className={classes.errorMessage}>
                  <ErrorIcon className={classes.errorIcon} />
                  Error: {errorMessage}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {successMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
