import { axiosInstance as instance } from './axiosInstance';
import { ApiToken, AppToken, Project, SdkToken } from './dataTypes';
import { PaginationParams } from './pagination';

export type GetProjectResponse = Project;

export type GetProjectsRequest = PaginationParams;

export type GetProjectsResponse = Project[];

export function getProject(projectId: string) {
  return instance.get<GetProjectResponse>(`/apps/${projectId}`);
}

export function deleteProject(projectId: string) {
  return instance.delete(`/apps/${projectId}`);
}

export function getProjects(request: GetProjectsRequest = {}) {
  return instance.get<GetProjectsResponse>('/apps', { params: request });
}

export type CreateApiTokenResponse = ApiToken;

export function createApiToken(projectId: string) {
  return instance.post<CreateApiTokenResponse>(`/apps/${projectId}/api-tokens`);
}

export function deleteApiToken(projectId: string, apiTokenId: string) {
  return instance.delete(`/apps/${projectId}/api-tokens/${apiTokenId}`);
}

export interface GetApiTokensRequest extends PaginationParams {}

export type GetApiTokensResponse = ApiToken[];

export function getApiTokens(
  projectId: string,
  request: GetApiTokensRequest = {}
) {
  return instance.get<GetApiTokensResponse>(`/apps/${projectId}/api-tokens`, {
    params: request,
  });
}

// Response is an array that is expected to always contain exactly 1 element
export type GetAppTokenResponse = AppToken[];

export function getAppToken(projectId: string) {
  return instance.get<GetAppTokenResponse>(`/apps/${projectId}/keys`);
}

export interface GetSdkTokensRequest extends PaginationParams {}

export type GetSdkTokensResponse = SdkToken[];

export function getSdkTokens(
  projectId: string,
  request: GetSdkTokensRequest = {}
) {
  return instance.get<GetSdkTokensResponse>(`/apps/${projectId}/sdk-tokens`, {
    params: request,
  });
}
