import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LisnrLogo from 'Assets/svg/lisnr-logo-icon-white.svg';
import LisnrPointIcon from 'Assets/svg/lisnr-point-icon-white.svg';
import LisnrRadiusIcon from 'Assets/svg/lisnr-radius-icon-white.svg';

import * as api from 'Api';
import { AppDispatch } from 'App/store';
import { setDrawerOpen } from 'Features/Main/drawerSlice';
import { selectIsAccountAdmin } from 'Features/User/userSlice';
import { productDisplayName } from 'Utils/productDisplayName';

import { getProjectActions } from './getProjectActions';

const useStyles = makeStyles((theme) => ({
  projectLink: {
    color: theme.palette.common.white,
  },
  productTypeLogo: {
    maxWidth: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
  },
  listContainer: {
    background: theme.palette.common.black + '00020',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[500],
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[500],
  },
  actionsHeading: {
    textTransform: 'uppercase',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  listItem: {
    padding: theme.spacing(1) / 2,
    paddingLeft: theme.spacing(1),
    '&.MuiListItem-button:hover': {
      background: theme.palette.grey[100] + '20',
    },
    '&.Mui-selected': {
      background: 0,
      color: theme.palette.lightGreen.main,
    },
  },
  icon: {
    color: 'inherit',
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  logoContainer: {
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
  },
  logo: {
    maxWidth: '100px',
    width: '100%',
    opacity: '10%',
  },
}));

export interface ProjectDrawerProps {
  project: api.Project;
  selectedActionName?: string;
}

export function ProjectDrawer({
  project,
  selectedActionName,
}: ProjectDrawerProps) {
  const dispatch: AppDispatch = useDispatch();
  const classes = useStyles();
  const isAccountAdmin = useSelector(selectIsAccountAdmin);
  const actions = getProjectActions(project, isAccountAdmin);

  const closeDrawer = () => {
    dispatch(setDrawerOpen(false));
  };

  return (
    <Box display="flex" flex="1" flexDirection="column">
      <Box flex="1 1 auto">
        <Box mx={1} my={2}>
          <Typography variant="h5" gutterBottom>
            <Link to={`/project/${project.id}`} className={classes.projectLink}>
              {project.name}
            </Link>
          </Typography>
          <Box display="flex" alignItems="center">
            {project.sdk_type !== 'legacy' ? (
              <img
                className={classes.productTypeLogo}
                alt={productDisplayName(project.sdk_type)}
                src={
                  {
                    point: LisnrPointIcon,
                    radius: LisnrRadiusIcon,
                  }[project.sdk_type]
                }
              />
            ) : null}
            <Typography variant="body2">
              {productDisplayName(project.sdk_type)}
            </Typography>
          </Box>
        </Box>
        <Box py={1} className={classes.listContainer}>
          <Typography variant="body2" className={classes.actionsHeading}>
            Manage
          </Typography>
          <List>
            {actions.map((action) => (
              <ListItem
                key={action.name}
                button
                component={Link}
                to={action.route}
                selected={selectedActionName === action.name}
                className={classes.listItem}
                onClick={closeDrawer}
              >
                <ListItemIcon className={classes.icon}>
                  {action.icon}
                </ListItemIcon>
                <ListItemText
                  primary={action.name}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box className={classes.logoContainer}>
        <img alt="LISNR Logo" src={LisnrLogo} className={classes.logo} />
      </Box>
    </Box>
  );
}

// TODO: ProjectDrawer test
