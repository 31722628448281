import { axiosInstance as instance } from './axiosInstance';
import { Sdk } from './dataTypes';
import { PaginationParams } from './pagination';

type GetSdksParams = Partial<
  Pick<Sdk, 'platform' | 'version' | 'architecture' | 'sdk_type'>
>;

export type GetSdksRequest = GetSdksParams & PaginationParams;

export type GetSdksResponse = Sdk[];

export function getSdks(request: GetSdksRequest) {
  return instance.get<GetSdksResponse>('/sdk-releases', { params: request });
}
