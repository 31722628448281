import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { camelCase } from 'lodash';

import * as api from 'Api';
import {
  accountSuspended,
  passwordChangeRequired,
  userDisabled,
} from 'App/errorActions';
import { RootState } from 'App/rootReducer';
import { loggedOut } from 'Features/Auth/logout';

export interface GetUserInfoResult {
  username: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  isAccountAdmin: boolean | null;
}

/**
 * Get user and account information.
 */
export const getUserInfo = createAsyncThunk<
  GetUserInfoResult,
  void,
  {
    rejectValue: api.ErrorResponse;
  }
>('user/getUserInfo', async (request, { rejectWithValue }) => {
  try {
    const { data } = await api.getUser();

    return {
      username: data.username,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      isAccountAdmin: data.is_account_admin,
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response.data as api.ErrorResponse);
  }
});

export interface UpdateUserRequest {
  username: string;
  firstName: string;
  lastName: string;
  email: string | null;
}
export interface UpdateUserResult {
  username: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface UpdateUserError {
  code: string;
  field?: keyof UpdateUserRequest;
  message: string;
}

export const updateUser = createAsyncThunk<
  UpdateUserResult,
  UpdateUserRequest,
  {
    rejectValue: UpdateUserError;
  }
>(
  'user/updateUser',
  async ({ username, firstName, lastName, email }, { rejectWithValue }) => {
    try {
      const { data } = await api.updateUser({
        first_name: firstName,
        last_name: lastName,
        username,
        email,
      });

      return {
        username: data.username,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let field;
      let { code, message } = error.response.data as api.ErrorResponse;
      if (code === 'invalid') {
        // Parse the errored field from message.
        // The error message may have form: "<field>: <message>".
        const [fieldName, fieldError] = message
          .split(':')
          .map((str) => str.trim());
        if (
          ['first_name', 'last_name', 'username', 'email'].includes(fieldName)
        ) {
          field = camelCase(fieldName) as UpdateUserError['field'];
          message = fieldError;
        }
      }

      return rejectWithValue({
        code,
        field,
        message,
      });
    }
  }
);

export interface UserState {
  userEnabled: boolean | null;
  passwordChangeRequired: boolean | null;
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  isAccountAdmin: boolean | null;
}

const initialState: UserState = {
  userEnabled: null,
  passwordChangeRequired: null,
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  isAccountAdmin: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get user info
    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      state.userEnabled = true;
      state.passwordChangeRequired = false;
      state.username = payload.username;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.isAccountAdmin = payload.isAccountAdmin;
    });

    // Update user
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.username = payload.username;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
    });

    // User disabled
    builder.addCase(userDisabled, (state) => {
      state.userEnabled = false;
    });

    // Password change required
    builder.addCase(passwordChangeRequired, (state) => {
      state.userEnabled = true;
      state.passwordChangeRequired = true;
    });

    // Account suspended
    builder.addCase(accountSuspended, (state) => {
      state.userEnabled = true;
    });

    // Logged out
    builder.addCase(loggedOut, () => {
      return initialState;
    });
  },
});

// Selectors
export const selectUserEnabled = (state: RootState) => state.user.userEnabled;
export const selectPasswordChangeRequired = (state: RootState) =>
  state.user.passwordChangeRequired;
export const selectUsername = (state: RootState) => state.user.username;
export const selectFirstName = (state: RootState) => state.user.firstName;
export const selectLastName = (state: RootState) => state.user.lastName;
export const selectEmail = (state: RootState) => state.user.email;
export const selectIsAccountAdmin = (state: RootState) =>
  state.user.isAccountAdmin;

export const selectFullName = ({
  user: { firstName, lastName },
}: RootState) => {
  let name = null;
  if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  } else if (firstName) {
    name = firstName;
  } else if (lastName) {
    name = lastName;
  }
  return name;
};

export default userSlice.reducer;
